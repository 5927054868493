import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { ComparisonChartComponent } from '../../parts/comparison-chart/comparison-chart.component';
import { ComparisonFiltersComponent } from '../../parts/comparison-filters/comparison-filters.component';
import { MessageBusService } from '../../../../../core/services/message-bus.service';
import { ChangePageTitleMessage } from '../../../../../core/messages/change-page-title.message';
import { CardModule } from 'primeng/card';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ReportApiService } from '../../../../../core/api/report.api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';
import { IComparisonChartData } from '../../../../../models/comparison/comparison-chart-data.interface';
import { IApiResponse } from '../../../../../core/interfaces/api-response.interface';

@Component({
  selector: 'app-comparison-page',
  templateUrl: './comparison-page.component.html',
  styleUrl: './comparison-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ComparisonChartComponent,
    ComparisonFiltersComponent,
    CardModule
  ]
})
export class ComparisonPageComponent implements OnInit {
  brands!: any[];
  channels!: any[];
  compare = ['Brands', 'Countries', 'Locations', 'Touch Points', 'Experiences'];
  groupedBy = ['Period', 'Brand', 'Country', 'Location', 'Touch Points', 'Experiences'];
  countries!: string[];
  outlets!: string[];
  periods = ['Q1', 'Q2', 'Q3', 'Q4'];
  years = [2024, 2023, 2022, 2021, 2020];

  comparisonChartData!: IComparisonChartData;
  comparisonFilterForm = new UntypedFormGroup({
    brands: new FormControl<string[]>([]),
    channels: new FormControl<string[]>([]),
    compare: new FormControl<string>(''),
    countries: new FormControl<string[]>([]),
    groupBy: new FormControl<string | null>(null),
    indicators: new FormControl<string[]>([]),
    outlets: new FormControl<string[]>([]),
    periods: new FormControl<string[]>([]),
    stages: new FormControl<string[]>([]),
    touchPoints: new FormControl<string[]>([]),
    year: new FormControl<number>(2024),
  });

  constructor(
    private dr: DestroyRef,
    private cdr: ChangeDetectorRef,
    private messageBusService: MessageBusService,
    private reportsApiService: ReportApiService,
  ) {}

  ngOnInit(): void {
    this.messageBusService.send(new ChangePageTitleMessage('COMPARISON'));
    this.comparisonFilterForm.valueChanges.pipe(
      takeUntilDestroyed(this.dr),
    ).subscribe(this.handleFilterChanged);

    this.reportsApiService.getComparisonChartFilters().pipe(take(1)).subscribe(this.handleComparisonFilters);
  }

  private handleFilterChanged = (filterValue: any): void => {
    this.reportsApiService.getComparisonChart(filterValue).pipe(take(1)).subscribe(this.handleComparisonChartData);
  }

  private handleComparisonFilters = (response: any): void => {
    this.countries = response.data.coutries.filter((_: any) => _._id).map((_: any) => _.country);
    this.outlets = response.data.outlets.filter((_: any) => _._id).map((_: any) => _.outlet);
    this.brands = response.data.brands.map((_: any) => ({ label: _.brand.name, value: _._id }));
    this.channels = response.data.channels.map((_: any) => _._id);
    const filters = { compare: 'Brands' };
    this.comparisonFilterForm.patchValue(filters);
  }

  private handleComparisonChartData = (response: IApiResponse<IComparisonChartData>): void => {
    this.comparisonChartData = response.data;
    this.cdr.detectChanges();
  }
}
