import { Injectable, isDevMode, Type } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IMessage } from '../interfaces/message.interface';
import { filter, map } from 'rxjs/operators';
import { EMessageChannel } from '../enums/message-channel.enum';

@Injectable({
  providedIn: 'root'
})
export class MessageBusService {
  private baseChannel = new Subject<any & IMessage>();
  private stackChannel = new BehaviorSubject<any & IMessage>(null);

  send(message: IMessage): void {
    if (isDevMode()) {
      console.log('MessageBusService >>> ', message);
    }

    if (message.channel === EMessageChannel.BASE) {
      this.baseChannel.next(message);
    } else if (message.channel === EMessageChannel.STACK) {
      this.stackChannel.next(message);
    } else {
      throw new Error(`MessageBusService: Unknown bus channel ${message.channel}`) as never;
    }
  }

  public of<T extends IMessage>(messageType: Type<T & IMessage>): Observable<T & IMessage> {
    const channel = (messageType as any).channel;
    let bus: Subject<any & IMessage>;

    if (channel === EMessageChannel.BASE) {
      bus = this.baseChannel;
    } else if (channel === EMessageChannel.STACK) {
      bus = this.stackChannel;
    } else {
      throw new Error(`MessageBusService: Unknown bus channel ${channel}`);
    }

    return bus.pipe(
      map((message: T & IMessage) => (message as T)),
      filter((message: T & IMessage) => Boolean(message)),
      filter((message: T & IMessage) => message.name === messageType.prototype.name)
    );
  }

  dispose(): void {
    this.baseChannel.complete();
    this.stackChannel.complete();
  }
}
