import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { DashboardAuditCardComponent } from '../../parts/dashboard-audit-card/dashboard-audit-card.component';
import { DashboardFiltersComponent } from '../../parts/dashboard-filters/dashboard-filters.component';
import { DashboardBrandIndicatorsComponent } from '../../parts/dashboard-brand-indicators/dashboard-brand-indicators.component';
import { DashboardPointsCardComponent } from '../../parts/dashboard-points-card/dashboard-points-card.component';
import { DashboardTasksPerformanceComponent } from '../../parts/dashboard-tasks-performance/dashboard-tasks-performance.component';
import { DashboardBrandJourneyComponent } from '../../parts/dashboard-brand-journey/dashboard-brand-journey.component';
import { DashboardTopStatsCardComponent } from '../../parts/dashboard-top-stats-card/dashboard-top-stats-card.component';
import { DashboardBrandTrendsComponent } from '../../parts/dashboard-brand-trends/dashboard-brand-trends.component';
import { DashboardTouchpointTrendsComponent } from '../../parts/dashboard-touchpoint-trends/dashboard-touchpoint-trends.component';
import { DashboardOverallRecommendationsComponent } from '../../parts/dashboard-overall-recommendations/dashboard-overall-recommendations.component';
import { DashboardStageTrendsComponent } from '../../parts/dashboard-stage-trends/dashboard-stage-trends.component';
import { DashboardTopLocationsComponent } from '../../parts/dashboard-top-locations/dashboard-top-locations.component';
import { DashboardTopCitiesComponent } from '../../parts/dashboard-top-cities/dashboard-top-cities.component';
import { DashboardTopCountriesComponent } from '../../parts/dashboard-top-countries/dashboard-top-countries.component';
import { DashboardTopTouchpointsComponent } from '../../parts/dashboard-top-touchpoints/dashboard-top-touchpoints.component';
import { GroupInfoComponent } from '../../../../../ui/components/group-info/group-info.component';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { MessageBusService } from '../../../../../core/services/message-bus.service';
import { ChangePageTitleMessage } from '../../../../../core/messages/change-page-title.message';
import { CardModule } from 'primeng/card';
import { IGroupsFullReport } from '../../../../../models/groups-full-report/groups-full-report.interface';
import { IApiResponse } from '../../../../../core/interfaces/api-response.interface';
import { IReportGroup } from '../../../../../models/report-groups/report-group.interface';
import { ReportApiService } from '../../../../../core/api/report.api.service';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { take } from 'rxjs';
import { GroupsService } from '../../../../../core/services/groups.service';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NoDataBlockComponent } from '../../../../../core/components/no-data-block/no-data-block.component';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrl: './dashboard-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DashboardAuditCardComponent,
    DashboardFiltersComponent,
    GroupInfoComponent,
    DashboardBrandIndicatorsComponent,
    DashboardPointsCardComponent,
    DashboardTasksPerformanceComponent,
    DashboardBrandJourneyComponent,
    DashboardTopStatsCardComponent,
    DashboardBrandTrendsComponent,
    DashboardTouchpointTrendsComponent,
    DashboardOverallRecommendationsComponent,
    TextComponent,
    DashboardStageTrendsComponent,
    DashboardTopLocationsComponent,
    DashboardTopCitiesComponent,
    DashboardTopCountriesComponent,
    DashboardTopTouchpointsComponent,
    CardModule,
    ProgressSpinnerModule,
    NoDataBlockComponent
  ],
})
export class DashboardPageComponent implements OnInit {
  cities: string[] = [];
  countries: string[] = [];
  locations: string[] = [];
  fullReport!: IGroupsFullReport;
  currentActiveGroup!: IReportGroup;
  reportsFilterForm = new UntypedFormGroup({
    brand: new FormControl<string[]>([]),
    channels: new FormControl<string[]>([]),
    city: new FormControl<string[]>([]),
    country: new FormControl<string[]>([]),
    outlet: new FormControl<string[]>([]),
    datesRange: new FormControl<Date[]>([]),
  });

  constructor(
    private dr: DestroyRef,
    private cdr: ChangeDetectorRef,
    private groupsService: GroupsService,
    private messageBusService: MessageBusService,
    private reportsApiService: ReportApiService,
  ) {}

  ngOnInit(): void {
    this.initializePage();
  }

  private initializePage(): void {
    this.reportsApiService.fetchActiveGroup().pipe(take(1)).subscribe(this.handleActiveGroups);
    this.messageBusService.send(new ChangePageTitleMessage('REPORTS'));
    this.subscribeToChanges();
  }

  private subscribeToChanges(): void {
    this.reportsFilterForm.valueChanges.pipe(
      takeUntilDestroyed(this.dr),
    ).subscribe(this.handleFilterChanged);
    this.groupsService.currentActiveGroup.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.dr),
    ).subscribe(this.getActiveGroupDependentData);
  }

  private handleFilterChanged = (filterValue: any): void => {
    const [from, to] = (filterValue.datesRange || []);

    delete filterValue.datesRange;
    this.getFullReport(this.currentActiveGroup, { ...filterValue, from: this.formatDate(from), to: this.formatDate(to) });
  }

  private formatDate(date: Date | null): string | null {
    if (!date) {
      return null;
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  private getActiveGroupDependentData = (activeGroup: IReportGroup): void => {
    this.currentActiveGroup = activeGroup;
    this.getFullReport(activeGroup, {});
    this.reportsApiService.getFilterCity(activeGroup._id).subscribe(_ => this.cities = _.data);
    this.reportsApiService.getFilterCountry(activeGroup._id).subscribe(_ => this.countries = _.data);
    this.reportsApiService.getFilterLocation(activeGroup._id).subscribe(_ => this.locations = _.data);
  }

  private handleActiveGroups = (response: IApiResponse<IReportGroup[]>): void => {
    this.groupsService.userGroups.next(response.data);
    this.groupsService.currentActiveGroup.next(response.data[0]);
  }

  private handleFullReport = (response: IApiResponse<IGroupsFullReport>): void => {
    this.fullReport = {
      ...response.data,
      painPoints: [
        { name: 'Slow load time', avg: 30 },
        { name: 'Navigation issues', avg: 40 },
        { name: 'Buggy checkout process', avg: 50 },
        { name: 'Unresponsive support', avg: 35 },
      ],
      delightPoints: [
        { name: 'Quick response times', avg: 90 },
        { name: 'User-friendly interface', avg: 80 },
        { name: 'Seamless integration', avg: 85 },
        { name: 'Frequent updates', avg: 95 },
      ],
    } as any;
    this.cdr.detectChanges();
  }

  private getFullReport(activeGroup: IReportGroup, filter: any): void {
    this.reportsApiService.getFullReport(activeGroup._id, filter).pipe(take(1)).subscribe(this.handleFullReport);
  }
}
