<div class="edit-profile-form-root flex flex-column" [formGroup]="form">
  <div class="avatar-block">
    <img
        class="user-avatar"
        alt=""
        width="110"
        height="110"
        appUploadFile
        [src]="avatar"
        (select)="handleFileChanged($event)"
    />

    @if (user && user.avatar !== form.get('avatar')!.value) {
      <p-button
          severity="help"
          icon="pi pi-undo"
          iconPos="right"
          label="Undo Avatar Change"
          size="small"
          [raised]="true"
          (click)="avatarPreview = ''; undoAvatarChange.emit()"
      />
    }
  </div>

  <p-floatLabel>
    <input
        id="first_name"
        type="text"
        pInputText
        formControlName="first_name"
    />
    <label for="first_name">Name</label>
  </p-floatLabel>

  <p-floatLabel>
    <input
        id="last_name"
        type="text"
        pInputText
        formControlName="last_name"
    />
    <label for="last_name">Surname</label>
  </p-floatLabel>

  <p-floatLabel>
    <input
        id="email"
        type="text"
        pInputText
        formControlName="email"
    />
    <label for="email">Email</label>
  </p-floatLabel>

  <p-floatLabel>
    <input
        id="phone"
        type="text"
        pInputText
        formControlName="phoneNumber"
    />
    <label for="phone">Phone</label>
  </p-floatLabel>

  <p-floatLabel>
    <p-inputNumber
        id="age"
        inputId="integeronly"
        formControlName="age"
    />
    <label for="age">Age</label>
  </p-floatLabel>

  <ng-content />
</div>
