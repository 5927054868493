import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'comparison-filters',
  templateUrl: './comparison-filters.component.html',
  styleUrl: './comparison-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DropdownModule,
    ReactiveFormsModule,
    MultiSelectModule
  ]
})
export class ComparisonFiltersComponent {
  @Input() form!: UntypedFormGroup;
  @Input() brands!: any[];
  @Input() channels!: any[];
  @Input() compare!: string[];
  @Input() groupedBy!: string[];
  @Input() countries!: string[];
  @Input() outlets!: string[];
  @Input() periods!: string[];
  @Input() years!: number[];
}
