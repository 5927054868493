import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ChangePasswordFormComponent } from '../../parts/change-password-form/change-password-form.component';
import { Button } from 'primeng/button';
import { AuthApiService } from '../../../../../core/api/auth.api.service';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrl: './change-password-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    ChangePasswordFormComponent,
    Button,
  ]
})
export class ChangePasswordPageComponent {
  changePasswordForm = new UntypedFormGroup({
    old_password: new FormControl<string>('', [Validators.required]),
    password: new FormControl<string>('', [Validators.required]),
    confirm_password: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private cdr: ChangeDetectorRef,
    private authApiService: AuthApiService,
    private uiService: UiService,
  ) {}

  updateUserPassword(): void {
    if (!this.changePasswordForm.valid) {
      this.changePasswordForm.markAsDirty();
      this.changePasswordForm.markAsTouched();
      this.cdr.markForCheck();

      return;
    }

    const newPasswordValue = this.changePasswordForm.get('password')!.value;
    const confirmationPasswordValue = this.changePasswordForm.get('confirm_password')!.value;
    if (newPasswordValue !== confirmationPasswordValue) {
      return;
    }

    this.authApiService.changePassword(this.changePasswordForm.value)
      .pipe(take(1))
      .subscribe(this.handlePasswordChanged);
  }

  private handlePasswordChanged = (): void => {
    this.uiService.toast$.next({
      severity: 'success',
      summary: 'Success',
      detail: 'Password has been changed successfully.'
    });
  }
}
