import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'dashboard-touchpoint-trends',
  templateUrl: './dashboard-touchpoint-trends.component.html',
  styleUrl: './dashboard-touchpoint-trends.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective],
})
export class DashboardTouchpointTrendsComponent {
  chartOptions: any = {
    textStyle: {
      color: '#B9B8CE',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
      textStyle: {
        color: '#B9B8CE',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: 'Email',
        type: 'line',
        stack: 'Total',
        data: [120, 132, 101, 134, 90, 230, 210],
        color: UiService.CHARTS_COLORS[0],
      },
      {
        name: 'Union Ads',
        type: 'line',
        stack: 'Total',
        data: [220, 182, 191, 234, 290, 330, 310],
        color: UiService.CHARTS_COLORS[1],
      },
      {
        name: 'Video Ads',
        type: 'line',
        stack: 'Total',
        data: [150, 232, 201, 154, 190, 330, 410],
        color: UiService.CHARTS_COLORS[2],
      },
      {
        name: 'Direct',
        type: 'line',
        stack: 'Total',
        data: [320, 332, 301, 334, 390, 330, 320],
        color: UiService.CHARTS_COLORS[3],
      },
      {
        name: 'Search Engine',
        type: 'line',
        stack: 'Total',
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        color: UiService.CHARTS_COLORS[4],
      }
    ],
    color: UiService.CHARTS_COLORS,
  };
}
