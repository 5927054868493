import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { GroupInfoComponent } from '../../../../../ui/components/group-info/group-info.component';
import { QuestionsFiltersComponent } from '../../parts/questions-filters/questions-filters.component';
import { QuestionsChartComponent } from '../../parts/questions-chart/questions-chart.component';
import { MessageBusService } from '../../../../../core/services/message-bus.service';
import { ChangePageTitleMessage } from '../../../../../core/messages/change-page-title.message';
import { CardModule } from 'primeng/card';
import { GroupsService } from '../../../../../core/services/groups.service';
import { ReportApiService } from '../../../../../core/api/report.api.service';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';
import { IApiResponse } from '../../../../../core/interfaces/api-response.interface';
import { IReportGroup } from '../../../../../models/report-groups/report-group.interface';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { IQuestionByIndicatorFilters } from '../../../../../models/questions/question-by-indicator-filters.interface';
import {
  GalleryFiltersComponent
} from '../../../../gallery/components/parts/gallery-filters/gallery-filters.component';

@Component({
  selector: 'app-questions-page',
  templateUrl: './questions-page.component.html',
  styleUrl: './questions-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GroupInfoComponent,
    QuestionsFiltersComponent,
    QuestionsChartComponent,
    CardModule,
    GalleryFiltersComponent
  ]
})
export class QuestionsPageComponent implements OnInit {
  brands: any[] = [];
  cities: string[] = [];
  countries: string[] = [];
  locations: string[] = [];
  questionsChartData!: IQuestionByIndicatorFilters[];
  currentActiveGroup!: IReportGroup;
  questionsFilterForm = new UntypedFormGroup({
    brand: new FormControl<string[]>([]),
    channels: new FormControl<string[]>([]),
    city: new FormControl<string[]>([]),
    country: new FormControl<string[]>([]),
    outlet: new FormControl<string[]>([]),
    period: new FormControl<Date[]>([]),
  });

  constructor(
    private dr: DestroyRef,
    private cdr: ChangeDetectorRef,
    private groupsService: GroupsService,
    private messageBusService: MessageBusService,
    private reportsApiService: ReportApiService,
  ) {}

  ngOnInit(): void {
    this.messageBusService.send(new ChangePageTitleMessage('QUESTIONS'));
    this.reportsApiService.fetchActiveGroup().pipe(take(1)).subscribe(this.handleActiveGroups);

    this.groupsService.currentActiveGroup.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.dr),
    ).subscribe(this.getActiveGroupDependentData);
    this.questionsFilterForm.valueChanges.pipe(
      takeUntilDestroyed(this.dr),
    ).subscribe(this.handleFilterChanged);
  }

  private handleActiveGroups = (response: IApiResponse<IReportGroup[]>): void => {
    this.groupsService.userGroups.next(response.data);
    this.groupsService.currentActiveGroup.next(response.data[0]);
  }

  private handleFilterChanged = (filterValue: any): void => {
    const [from, to] = (filterValue.period || []);

    delete filterValue.period;
    this.getQuestionsByIndicatorFilters(this.currentActiveGroup, { ...filterValue, from: this.formatDate(from), to: this.formatDate(to) });
  }

  private getQuestionsByIndicatorFilters(activeGroup: IReportGroup, filter: any): void {
    this.reportsApiService.getQuestionsByIndicatorFilters(activeGroup._id, filter)
      .pipe(take(1))
      .subscribe(this.handleBrandsChartData);
  }

  private getActiveGroupDependentData = (activeGroup: IReportGroup): void => {
    this.currentActiveGroup = activeGroup;
    this.getQuestionsByIndicatorFilters(activeGroup, {});
    this.reportsApiService.getFilterCity(activeGroup._id).subscribe(_ => this.cities = _.data);
    this.reportsApiService.getFilterCountry(activeGroup._id).subscribe(_ => this.countries = _.data);
    this.reportsApiService.getFilterLocation(activeGroup._id).subscribe(_ => this.locations = _.data);
    this.brands = activeGroup.connected_brands.map(_ => ({
      label: _.brand_id.name,
      value: _.brand_id._id
    }))
  }

  private handleBrandsChartData = (response: IApiResponse<IQuestionByIndicatorFilters[]>): void => {
    this.questionsChartData = response.data;
    this.cdr.detectChanges();
  }

  private formatDate(date: Date | null): string | null {
    if (!date) {
      return null;
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}
