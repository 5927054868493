import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges, QueryList, Renderer2,
  SimpleChanges, ViewChildren
} from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { IGroupFullReportStage } from '../../../../../models/groups-full-report/group-full-report-stage.interface';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { ReportApiService } from '../../../../../core/api/report.api.service';
import { map } from 'rxjs/operators';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { UiService } from '../../../../../core/services/ui.service';

function makeText(valOnRadian: any, _valOnRadianMax: any): string {
  if (valOnRadian < -10) {
    alert('illegal during val: ' + valOnRadian);
  }

  return ((valOnRadian / _valOnRadianMax) * 100).toFixed(0) + '%';
}

function makePointerPoints(
  renderItemParams: any,
  polarEndRadian: any,
  _outerRadius: any,
  _pointerInnerRadius: any,
) {
  return [
    convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian),
    convertToPolarPoint(
      renderItemParams,
      _outerRadius,
      polarEndRadian + Math.PI * 0.03
    ),
    convertToPolarPoint(renderItemParams, _pointerInnerRadius, polarEndRadian)
  ];
}

function convertToPolarPoint(renderItemParams: any, radius: any, radian: any): any {
  return [
    Math.cos(radian) * radius + renderItemParams.coordSys.cx,
    -Math.sin(radian) * radius + renderItemParams.coordSys.cy
  ];
}

@Component({
  selector: 'dashboard-brand-journey',
  templateUrl: './dashboard-brand-journey.component.html',
  styleUrl: './dashboard-brand-journey.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective, AsyncPipe, TextComponent],
})
export class DashboardBrandJourneyComponent implements OnChanges {
  @Input() stages!: IGroupFullReportStage[];
  @ViewChildren('chart', { read: ElementRef }) charts!: QueryList<ElementRef<HTMLDivElement>>;

  activeStageIndex!: number;
  activeStageIndexOptions: any;

  _panelImageURL = '/assets/images/custom-gauge-panel.png';
  _animationDuration = 2000;
  _animationDurationUpdate = 1000;
  _animationEasingUpdate = 'quarticInOut';
  _valOnRadianMax = 100;
  _outerRadius = 70;
  _innerRadius = 50;
  _pointerInnerRadius = 20;
  _insidePanelRadius = 40;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private reportsApiService: ReportApiService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { stages } = changes;
    if (stages?.currentValue && stages?.currentValue !== stages?.previousValue) {
      if (!this.stages.length) {
        return;
      }

      this.selectActiveStage(0);
      setTimeout(() => {
        this.charts.forEach((block: ElementRef<HTMLDivElement>) => {
          this.renderer.setStyle(block.nativeElement, 'width', `calc(100% / ${this.stages.length} - 1%)`);
        });
      });
    }
  }

  selectActiveStage(index: number): void {
    if (this.activeStageIndex === index) {
      return;
    }

    this.activeStageIndex = index;
    this.getActiveStageOptions(index).subscribe((options: any) => {
      this.activeStageIndexOptions = options;
      this.cdr.detectChanges();
    });
  }

  renderItem = (params: any, api: any): any => {
    const classCtx = this;
    const valOnRadian = api.value(1);
    const coords = api.coord([api.value(0), valOnRadian]);
    const polarEndRadian = coords[3];
    const imageStyle = {
      image: classCtx._panelImageURL,
      x: params.coordSys.cx - classCtx._outerRadius,
      y: params.coordSys.cy - classCtx._outerRadius,
      width: classCtx._outerRadius * 2,
      height: classCtx._outerRadius * 2
    };

    return {
      type: 'group',
      children: [
        {
          type: 'image',
          style: imageStyle,
          clipPath: {
            type: 'sector',
            shape: {
              cx: params.coordSys.cx,
              cy: params.coordSys.cy,
              r: classCtx._outerRadius,
              r0: classCtx._innerRadius,
              startAngle: 0,
              endAngle: -polarEndRadian,
              transition: 'endAngle',
              enterFrom: { endAngle: 0 }
            }
          }
        },
        // {
        //   type: 'image',
        //   style: imageStyle,
        //   clipPath: {
        //     type: 'polygon',
        //     shape: {
        //       points: makePointerPoints(params, polarEndRadian, classCtx._outerRadius, classCtx._pointerInnerRadius),
        //     },
        //     extra: {
        //       polarEndRadian: polarEndRadian,
        //       transition: 'polarEndRadian',
        //       enterFrom: { polarEndRadian: 0 }
        //     },
        //     during: function (apiDuring: any) {
        //       apiDuring.setShape(
        //         'points',
        //         makePointerPoints(params, apiDuring.getExtra('polarEndRadian'), classCtx._outerRadius, classCtx._pointerInnerRadius)
        //       );
        //     }
        //   }
        // },
        {
          type: 'circle',
          shape: {
            cx: params.coordSys.cx,
            cy: params.coordSys.cy,
            r: classCtx._insidePanelRadius
          },
          style: {
            fill: '#fff',
            shadowBlur: 25,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: 'rgba(76, 107, 167, 0.4)'
          }
        },
        {
          type: 'text',
          extra: {
            valOnRadian: valOnRadian,
            transition: 'valOnRadian',
            enterFrom: { valOnRadian: 0 }
          },
          style: {
            text: makeText(valOnRadian, classCtx._valOnRadianMax),
            fontSize: 22,
            fontWeight: 700,
            x: params.coordSys.cx,
            y: params.coordSys.cy,
            fill: 'rgb(0,50,190)',
            align: 'center',
            verticalAlign: 'middle',
            enterFrom: { opacity: 0 },
            fontFamily: 'Poppins',
            fontStyle: 'normal',
          },
          during: function (apiDuring: any): void {
            apiDuring.setStyle(
              'text',
              makeText(apiDuring.getExtra('valOnRadian'), classCtx._valOnRadianMax)
            );
          }
        }
      ]
    };
  }

  getOptions(stage: IGroupFullReportStage): any {
    return {
      animationEasing: this._animationEasingUpdate,
      animationDuration: this._animationDuration,
      animationDurationUpdate: this._animationDurationUpdate,
      animationEasingUpdate: this._animationEasingUpdate,
      dataset: {
        source: [[1, stage.avg]]
      },
      tooltip: {},
      angleAxis: {
        type: 'value',
        startAngle: 0,
        show: false,
        min: 0,
        max: this._valOnRadianMax
      },
      radiusAxis: {
        type: 'value',
        show: false
      },
      polar: {},
      series: [
        {
          type: 'custom',
          coordinateSystem: 'polar',
          renderItem: this.renderItem
        }
      ]
    };
  }

  getActiveStageOptions(index: number): Observable<any> {
    return this.reportsApiService.fetchBrandJourneyActiveStage(this.stages[index]._id).pipe(
      map((response) => {
        return {
          textStyle: {
            color: '#B9B8CE',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 'normal',
          },
          dataset: {
            source: [
              ['score', 'amount', 'product'],
              ...response.data.map((item: any) => (
                [Math.floor(Math.random() * 100), item.usepercent, item.name]
              )),
            ]
          },
          grid: { containLabel: true },
          xAxis: { name: 'percentage' },
          yAxis: { type: 'category' },
          series: [
            {
              type: 'bar',
              encode: {
                x: 'amount',
                y: 'product'
              },
              itemStyle: {
                color: (param: any) => UiService.CHARTS_COLORS[param.dataIndex],
              },
            }
          ],
          color: UiService.CHARTS_COLORS,
        };
      })
    );
  }
}
