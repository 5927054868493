import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { IGallery } from '../../../../../models/gallery/gallery.interface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GalleryInfoComponent } from '../gallery-info/gallery-info.component';

@Component({
  selector: 'gallery-preview',
  templateUrl: './gallery-preview.component.html',
  styleUrl: './gallery-preview.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class GalleryPreviewComponent implements OnDestroy {
  @Input() gallery!: IGallery;
  @HostBinding('class.gallery-preview') readonly baseClass = true;

  ref: DynamicDialogRef | undefined;

  constructor(public dialogService: DialogService) {}

  ngOnDestroy(): void {
    if (!this.ref) {
      return;
    }

    this.ref.close();
  }

  openDetails(gallery: IGallery, file: string): void {
    this.ref = this.dialogService.open(GalleryInfoComponent, {
      header: gallery.question.question.split(':')?.[0],
      width: '80vw',
      modal: true,
      data: { gallery, file },
    });
  }
}
