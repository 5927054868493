import { EMessageChannel } from '../enums/message-channel.enum';
import { IMessage } from '../interfaces/message.interface';

export function Message(name: string, channel: EMessageChannel = EMessageChannel.BASE) {
  return function <T extends {new (...args: any[]): IMessage}>(constructor: T): void {
    constructor.prototype.name = name;
    (constructor as any).channel = channel;
    constructor.prototype.channel = channel;
  };
}
