import { Routes } from '@angular/router';
import { RoutesHelper } from './core/helpers/routes.helper';
import { AuthorizedLayoutComponent } from './layout/authorized-layout/authorized-layout.component';
import { AuthPageComponent } from './auth/components/auth-page/auth-page.component';
import { QuestionsPageComponent } from './content-pages/questions/components/pages/questions-page/questions-page.component';
import {
  ComparisonPageComponent
} from './content-pages/comparison/components/pages/comparison-page/comparison-page.component';
import { TrendsPageComponent } from './content-pages/trends/components/pages/trends-page/trends-page.component';
import {
  DashboardPageComponent
} from './content-pages/dashboard/components/pages/dashboard-page/dashboard-page.component';
import { PageNotFoundComponent } from './common-pages/page-not-found/page-not-found.component';
import { GalleryPageComponent } from './content-pages/gallery/components/pages/gallery-page/gallery-page.component';
import {
  EditProfilePageComponent
} from './content-pages/edit-profile/components/pages/edit-profile-page/edit-profile-page.component';
import {
  ChangePasswordPageComponent
} from './content-pages/change-password/components/pages/change-password-page/change-password-page.component';

export const routes: Routes = [
  {
    path: RoutesHelper.EMPTY_PATH,
    component: AuthorizedLayoutComponent,
    children: [
      {
        path: RoutesHelper.EMPTY_PATH,
        pathMatch: 'full',
        redirectTo: RoutesHelper.DASHBOARD_PATH,
      },
      {
        path: RoutesHelper.DASHBOARD_PATH,
        loadComponent: () => DashboardPageComponent,
      },
      {
        path: RoutesHelper.QUESTIONS_PATH,
        loadComponent: () => QuestionsPageComponent,
      },
      {
        path: RoutesHelper.GALLERY_PATH,
        loadComponent: () => GalleryPageComponent,
      },
      {
        path: RoutesHelper.COMPARISON_PATH,
        loadComponent: () => ComparisonPageComponent,
      },
      {
        path: RoutesHelper.TRENDS_PATH,
        loadComponent: () => TrendsPageComponent,
      },
      {
        path: RoutesHelper.EDIT_PROFILE_PATH,
        loadComponent: () => EditProfilePageComponent,
      },
      {
        path: RoutesHelper.CHANGE_PASSWORD_PATH,
        loadComponent: () => ChangePasswordPageComponent,
      },
    ],
  },
  {
    path: RoutesHelper.AUTH_PATH,
    loadComponent: () => AuthPageComponent,
  },
  {
    path: '**',
    redirectTo: RoutesHelper.NOT_FOUND_PATH,
  },
  {
    path: RoutesHelper.NOT_FOUND_PATH,
    loadComponent: () => PageNotFoundComponent,
  },
];
