<div class="app-auth-page-root">
  <p-card header="Login">
    <div class="login-form">
      <input
          type="text"
          autocomplete="off"
          pInputText
          [formControl]="$any(loginForm.get('email'))"
      />

      <input
          type="password"
          autocomplete="off"
          pInputText
          [formControl]="$any(loginForm.get('password'))"
      />

      <p-button label="Login" [raised]="true" (click)="login()" />
    </div>
  </p-card>
</div>
