<div class="no-data-block-root flex flex-column align-center">
  <img
      class="no-data-icon"
      src="/assets/svg/no-data-icon.svg"
      alt="No data"
  />

  <ms-text class="text-content" color="#ffffff" fontSize="20px">No data found</ms-text>
  <ms-text class="text-content -second" color="#9a9a9a">
    Try adjusting your search <br /> to find what are you looking for
  </ms-text>
</div>