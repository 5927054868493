import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'dashboard-stage-trends',
  templateUrl: './dashboard-stage-trends.component.html',
  styleUrl: './dashboard-stage-trends.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective],
})
export class DashboardStageTrendsComponent {
  chartOptions: any = {
    textStyle: {
      color: '#B9B8CE',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let content = `${params[0].axisValue}<br/>`;
        params.forEach((item: any) => {
          content += `<div style="color:${item.color}">●</div> ${item.seriesName}: ${item.data}<br/>`;
        });
        return content;
      },
    },
    legend: {
      data: ['Pre-production', 'Production', 'Post-production', 'Marketing', 'Feedback'],
      textStyle: {
        color: '#B9B8CE',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}%',
      },
    },
    series: [
      {
        name: 'Pre-production',
        type: 'line',
        stack: 'Total',
        data: [15, 20, 25, 30, 35], // Данные для этапа Pre-production
        color: UiService.CHARTS_COLORS[0],
      },
      {
        name: 'Production',
        type: 'line',
        stack: 'Total',
        data: [25, 35, 45, 55, 65], // Данные для этапа Production
        color: UiService.CHARTS_COLORS[1],
      },
      {
        name: 'Post-production',
        type: 'line',
        stack: 'Total',
        data: [20, 30, 40, 50, 60], // Данные для этапа Post-production
        color: UiService.CHARTS_COLORS[2],
      },
      {
        name: 'Marketing',
        type: 'line',
        stack: 'Total',
        data: [10, 20, 30, 40, 50], // Данные для этапа Marketing
        color: UiService.CHARTS_COLORS[3],
      },
      {
        name: 'Feedback',
        type: 'line',
        stack: 'Total',
        data: [5, 15, 25, 35, 45], // Данные для этапа Feedback
        color: UiService.CHARTS_COLORS[4],
      },
    ],
  };
}
