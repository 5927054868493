<div class="questions-page-root">
  <div class="row">
    <group-info />
  </div>

  <div class="row">
    <p-card>
      <questions-filters [form]="questionsFilterForm" [brands]="brands" [cities]="cities" [countries]="countries" [locations]="locations" />
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <questions-chart [data]="questionsChartData" />
    </p-card>
  </div>
</div>
