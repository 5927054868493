import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUserPreferences } from '../interfaces/user-preferences.interface';
import { IToast } from '../interfaces/toast.interface';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  static readonly CHARTS_COLORS = [
    '#5784BA',
    '#F4CFDF',
    '#F7F6CF',
    '#B6D8F2',
    '#E6A57E',
    '#CCD4BF',
    '#9AC8EB',
    '#E7CBA9',
    '#F5F3E7',
    '#EEBAB2',
    '#F5E2E4',
    '#F5BFD2',
    '#E5DB9C',
    '#D0BCAC',
    '#BEB4C5',
  ];

  readonly userPreferences = new BehaviorSubject<IUserPreferences>({
    colorTheme: JSON.parse(localStorage.getItem('ms-preferences')!)?.colorTheme
  });
  readonly toast$ = new BehaviorSubject<IToast | null>(null);

  setColorTheme(colorTheme: 'light' | 'dark'): void {
    const updatedUserPreferences = { ...this.userPreferences.getValue(), colorTheme };
    this.userPreferences.next(updatedUserPreferences);
    localStorage.setItem('ms-preferences', JSON.stringify(updatedUserPreferences));
  }

  getUserPreferences(): IUserPreferences {
    return this.userPreferences.getValue() || JSON.parse(localStorage.getItem('ms-preferences')!);
  }
}
