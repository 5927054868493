import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { IFullReportAudit } from '../../../../../models/groups-full-report/full-report-audit.interface';

@Component({
  selector: 'dashboard-audit-card',
  templateUrl: './dashboard-audit-card.component.html',
  styleUrl: './dashboard-audit-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextComponent
  ],
})
export class DashboardAuditCardComponent {
  @Input() auditType!: string;
  @Input() audit!: IFullReportAudit;
}
