@if (spinner$ | async; as spinner) {
  <p-progressSpinner
  [strokeWidth]="spinner.strokeWidth"
  [animationDuration]="spinner.animationDuration"
      [fill]="spinner.fill"
      [style]="spinner.style"
      [styleClass]="spinner.styleClass"
      [ariaLabel]="spinner.ariaLabel"
  />
}
<p-toast [life]="5000" />
<router-outlet />
