<div class="dashboard-overall-recommendations-root">
  <div class="flex flex-column mb-20">
    <label for="title" class="mb-8">
      <ms-text weight="300" color="#9a9a9a">Title</ms-text>
    </label>
    <input
        id="title"
        type="text"
        autocomplete="off"
        pInputText
        [formControl]="$any(form.get('title'))"
    />
  </div>

  <div class="flex flex-column mb-20">
    <ms-text weight="300" color="#9a9a9a" class="mb-8">Recommendation Text</ms-text>
    <p-editor [formControl]="$any(form.get('text'))" [style]="{ height: '320px' }" />
  </div>

  <div class="card flex flex-column">
    <p-toast />
    <p-fileUpload
        mode="basic"
        chooseLabel="Select File"
        chooseIcon="pi pi-upload"
        name="demo[]"
        url="https://www.primefaces.org/cdn/api/upload.php"
        accept="image/*"
        maxFileSize="1000000"
        (onUpload)="onUpload($event)" />
  </div>
</div>
