import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { IGroupsFullReportCity } from '../../../../../models/groups-full-report/groups-full-report-city.interface';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'dashboard-top-cities',
  templateUrl: './dashboard-top-cities.component.html',
  styleUrl: './dashboard-top-cities.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxEchartsDirective],
  providers: [provideEcharts()],
})
export class DashboardTopCitiesComponent {
  @Input() cities!: IGroupsFullReportCity[];

  buildChartOptions(cities: IGroupsFullReportCity[]): any {
    return {
      textStyle: {
        color: '#B9B8CE',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      dataset: {
        source: [
          ['score', 'amount', 'city'],
          ...cities.map((city: IGroupsFullReportCity) => (
            [Math.floor(Math.random() * 100), city.avg, city.name]
          )),
        ],
      },
      grid: { containLabel: true },
      xAxis: { name: 'amount' },
      yAxis: { type: 'category' },
      series: [
        {
          type: 'bar',
          encode: {
            x: 'amount',
            y: 'city'
          },
          itemStyle: {
            color: (param: any) => UiService.CHARTS_COLORS[param.dataIndex],
          },
        }
      ],
      color: UiService.CHARTS_COLORS,
    };
  }
}
