import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../env/environment';
import { IApiResponse } from '../interfaces/api-response.interface';
import { IGallery } from '../../models/gallery/gallery.interface';

@Injectable({
  providedIn: 'root'
})
export class GalleryApiService {
  constructor(private http: HttpClient) {}

  getGalleries(activeGroupId: string, filters: any): Observable<IApiResponse<IGallery[]>> {
    return this.http.post<IApiResponse<IGallery[]>>(`${environment.apiUrl}/analytic/gallery`, { groupId: activeGroupId, ...filters });
  }
}
