import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';

@Component({
  selector: 'dashboard-top-stats-card',
  templateUrl: './dashboard-top-stats-card.component.html',
  styleUrl: './dashboard-top-stats-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxEchartsDirective],
  providers: [provideEcharts()],
})
export class DashboardTopStatsCardComponent {
  @Input() chartOptions!: any;
}
