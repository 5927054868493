import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { IBrandsChartByGroup } from '../../../../../models/trends/brands-chart-by-group.interface';
import { IBrandsChartSeries } from '../../../../../models/trends/brands-chart-series.interface';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {
  DashboardPointsCardComponent
} from '../../../../dashboard/components/parts/dashboard-points-card/dashboard-points-card.component';
import { NoDataBlockComponent } from '../../../../../core/components/no-data-block/no-data-block.component';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'trends-brands-trends',
  templateUrl: './trends-brands-trends.component.html',
  styleUrl: './trends-brands-trends.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective, ProgressSpinnerModule, DashboardPointsCardComponent, NoDataBlockComponent],
})
export class TrendsBrandsTrendsComponent implements OnChanges {
  @Input() data?: IBrandsChartByGroup;

  chartOptions!: any;

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;
    if (data?.currentValue && data?.currentValue !== data?.previousValue) {
      this.chartOptions = this.buildChartOptions(this.data!);
    }
  }

  buildChartOptions(data: IBrandsChartByGroup): any {
    return {
      textStyle: {
        color: '#B9B8CE',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: data.series.map((seriesItem: IBrandsChartSeries) => seriesItem.name),
        textStyle: {
          color: '#B9B8CE',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 'normal',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        categories: data.xAxis,
        data: data.xAxis.map((month: string) => ({ value: month })),
      },
      yAxis: {
        type: 'value',
      },
      series: data.series.map((seriesItem: IBrandsChartSeries) => ({
        type: 'line',
        ...seriesItem,
        itemStyle: {
          color: (param: any) => UiService.CHARTS_COLORS[param.dataIndex],
        },
      })),
      color: UiService.CHARTS_COLORS,
    };
  }
}
