import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApiResponse } from '../interfaces/api-response.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../env/environment';
import { IUser } from '../../models/user/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  constructor(
    private http: HttpClient
  ) {}

  login(formData: { email: string; password: string }): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/auth/admin-login`,
      formData
    );
  }

  resetPassword(formData: any): Observable<IApiResponse> {
    return this.http.put<IApiResponse>(
      `${environment.apiUrl}/auth/reset-password`,
      {
        password: formData.newPassword,
        confirm_password: formData.confirmPassword,
      }
    );
  }

  verifyForgotPasswordOtp(formData: any): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/auth/verify-otp`,
      formData
    );
  }

  sendForgotPasswordOtp(formData: any): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/auth/forgot-password`,
      formData
    );
  }

  getCurrentUser(): Observable<IUser> {
    return this.http.get<IUser>(`${environment.apiUrl}/auth/me`);
  }

  uploadFile = (file: any): Observable<IApiResponse> => {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<IApiResponse>(`${environment.apiUrl}/admin/upload-files`, formData);
  }
  
  changePassword(model: any): Observable<IApiResponse<any>> {
    return this.http.put<IApiResponse<any>>(`${environment.apiUrl}/auth/change-password`, model);
  }

  updateProfile(model: IUser): Observable<IApiResponse<any>> {
    return this.http.put<IApiResponse<any>>(`${environment.apiUrl}/auth/client/profile`, model);
  }
}
