<div class="comparison-page-root">
  <div class="row">
    <p-card>
      <comparison-filters
          [form]="comparisonFilterForm"
          [brands]="brands"
          [channels]="channels"
          [compare]="compare"
          [groupedBy]="groupedBy"
          [countries]="countries"
          [outlets]="outlets"
          [periods]="periods"
          [years]="years"
      />
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <comparison-chart [data]="comparisonChartData" />
    </p-card>
  </div>
</div>
