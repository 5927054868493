import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UiService } from './core/services/ui.service';
import { filter } from 'rxjs/operators';
import { IUserPreferences } from './core/interfaces/user-preferences.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { IToast } from './core/interfaces/toast.interface';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet, ToastModule, ProgressSpinnerModule, AsyncPipe],
  providers: [MessageService],
})
export class AppComponent implements OnInit {
  readonly spinner$ = this.uiService.spinner$.asObservable();

  constructor(
    private dr: DestroyRef,
    private renderer: Renderer2,
    private uiService: UiService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.uiService.userPreferences.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.dr),
    ).subscribe(this.handleUserPreferences);

    this.uiService.toast$.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.dr),
    ).subscribe((toast: IToast) => this.messageService.add(toast));
  }

  private handleUserPreferences = (preferences: IUserPreferences): void => {
    const classToRemove = preferences.colorTheme === 'dark' ? 'light' : 'dark';
    this.renderer.removeClass(document.body, `-ms-${classToRemove}`)
    this.renderer.addClass(document.body, `-ms-${preferences.colorTheme}`);
  }
}
