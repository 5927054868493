<div class="dashboard-brand-journey-root flex flex-column">
  <div class="stages-row flex justify-space-between align-center">
    @for (stage of stages; track stage._id; let index = $index) {
      <div
          class="stage flex flex-column align-center"
          [class.-active]="activeStageIndex === index"
          (click)="selectActiveStage(index)"
          #chart
      >
        <ms-text class="stage-name" weight="700" fontSize="18px">{{ stage.name }}</ms-text>
        <div echarts [options]="getOptions(stage)" style="width: 180px; height: 150px"></div>
      </div>
    }
  </div>

  <div class="active-stage flex flex-column">
    @if (activeStageIndex !== null) {
      <div echarts [options]="activeStageIndexOptions"></div>
    }
  </div>
</div>
