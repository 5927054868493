import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';

@Component({
  selector: 'trends-filters',
  templateUrl: './trends-filters.component.html',
  styleUrl: './trends-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DropdownModule,
    CalendarModule,
    FloatLabelModule,
    MultiSelectModule,
    PaginatorModule,
    ReactiveFormsModule
  ]
})
export class TrendsFiltersComponent {
  @Input() form!: UntypedFormGroup;
  @Input() brands!: any[];
  @Input() cities!: string[];
  @Input() countries!: string[];
  @Input() locations!: string[];
}
