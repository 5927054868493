import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { TextComponent } from '../text/text.component';
import { AsyncPipe } from '@angular/common';
import { GroupsService } from '../../../core/services/groups.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { IReportGroup } from '../../../models/report-groups/report-group.interface';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'group-info',
  templateUrl: './group-info.component.html',
  styleUrl: './group-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextComponent,
    AsyncPipe,
    OverlayPanelModule,
    ProgressSpinnerModule
  ]
})
export class GroupInfoComponent {
  @HostBinding('class.group-info') baseClass = true;

  userGroups$ = this.groupsService.userGroups.asObservable();
  currentActiveGroup$ = this.groupsService.currentActiveGroup.asObservable();

  constructor(
    private groupsService: GroupsService,
  ) {}

  setActiveGroup(groupToSet: IReportGroup, currentActiveGroup: IReportGroup): void {
    if (groupToSet._id === currentActiveGroup._id) {
      return;
    }

    this.groupsService.currentActiveGroup.next(groupToSet);
  }
}
