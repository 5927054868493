import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TextComponent } from '../../ui/components/text/text.component';
import { Button } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { RoutesHelper } from '../../core/helpers/routes.helper';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextComponent,
    Button,
    RouterLink
  ]
})
export class PageNotFoundComponent {
  readonly routesHelper = RoutesHelper;
}
