import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  Input,
  OnChanges, QueryList,
  Renderer2,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import {
  IGroupsFullReportIndicators
} from '../../../../../models/groups-full-report/groups-full-report-indicators.interface';

@Component({
  selector: 'dashboard-brand-indicators',
  templateUrl: './dashboard-brand-indicators.component.html',
  styleUrl: './dashboard-brand-indicators.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective],
})
export class DashboardBrandIndicatorsComponent implements OnChanges {
  @Input() indicators!: IGroupsFullReportIndicators[];
  @ViewChildren('chart', { read: ElementRef }) charts!: QueryList<ElementRef<HTMLDivElement>>;

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { indicators } = changes;
    if (indicators?.currentValue && indicators?.currentValue !== indicators.previousValue) {
      setTimeout(() => {
        this.charts.forEach((block: ElementRef<HTMLDivElement>) => {
          this.renderer.setStyle(block.nativeElement, 'width', `calc(100% / ${this.indicators.length} - 1%)`);
        });
      });
    }
  }

  getChartOptions(indicator: IGroupsFullReportIndicators): any {
    return {
      textStyle: {
        color: '#ffffff',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          center: ['50%', '75%'],
          radius: '90%',
          min: 0,
          max: 1,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 4,
              color: [
                [0.25, '#FF6E76'],
                [0.5, '#FDDD60'],
                [0.75, '#58D9F9'],
                [1, '#7CFFB2']
              ]
            }
          },
          pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            length: '12%',
            width: 16,
            offsetCenter: [0, '-65%'],
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            length: 10,
            lineStyle: {
              color: 'auto',
              width: 2
            }
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto',
              width: 4
            }
          },
          axisLabel: {
            color: '#B9B8CE',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 14,
            distance: -45,
            rotate: 'tangential',
            formatter: function (value: number): string {
              if (value === 0.875) {
                return 'Great';
              } else if (value === 0.625) {
                return 'Fair';
              } else if (value === 0.375) {
                return 'Low';
              } else if (value === 0.125) {
                return 'Poor';
              }

              return '';
            }
          },
          title: {
            offsetCenter: [0, '-20%'],
            color: '#B9B8CE',
            fontSize: 20,
          },
          detail: {
            fontSize: 22,
            offsetCenter: [0, '-35%'],
            valueAnimation: true,
            formatter: function (value: number): string {
              return Math.round(value * 100) + '';
            },
            color: 'inherit'
          },
          data: [
            {
              value: indicator.avg / 100,
              name: indicator.name
            }
          ],
        }
      ]
    };
  }
}
