<div class="comparison-chart-root">
  @if (chartOptions && !$any(data)?.series?.length) {
    <no-data-block style="height: 400px;" />
  } @else if (data?.series?.length) {
    <div class="chart" echarts [options]="chartOptions"></div>
  } @else {
    <div class="flex justify-center align-center" style="height: 400px;">
      <p-progressSpinner ariaLabel="loading" />
    </div>
  }
</div>
