<div class="app-header-root">
  <div class="logo-with-page-title-container">
    <img
        class="app-logo"
        alt="Mysterya Logo"
        [src]="currentColorTheme === 'light' ? '/assets/svg/mysterya-logo-dark.svg' : '/assets/svg/mysterya-logo.svg'"
        [routerLink]="[routesHelper.ROOT_PATH, routesHelper.DASHBOARD_PATH]"
    />

    <ms-text
        class="page-title"
        fontSize="18px"
        weight="300"
        color="#FFFFFF"
    >
      {{ pageTitle }}
    </ms-text>
  </div>

  <div class="navigation">
    <div
        class="menu-item"
        [routerLink]="[routesHelper.ROOT_PATH, routesHelper.DASHBOARD_PATH]"
        routerLinkActive="-active"
    >
      <ms-text weight="600" fontSize="13px">REPORTS</ms-text>
    </div>
    <div
        class="menu-item"
        [routerLink]="[routesHelper.ROOT_PATH, routesHelper.QUESTIONS_PATH]"
        routerLinkActive="-active"
    >
      <ms-text weight="600" fontSize="13px">QUESTIONS</ms-text>
    </div>
    <div
        class="menu-item"
        [routerLink]="[routesHelper.ROOT_PATH, routesHelper.GALLERY_PATH]"
        routerLinkActive="-active"
    >
      <ms-text weight="600" fontSize="13px">GALLERY</ms-text>
    </div>
    <div
        class="menu-item"
        [routerLink]="[routesHelper.ROOT_PATH, routesHelper.COMPARISON_PATH]"
        routerLinkActive="-active"
    >
      <ms-text weight="600" fontSize="13px">COMPARISON</ms-text>
    </div>
    <div
        class="menu-item"
        [routerLink]="[routesHelper.ROOT_PATH, routesHelper.TRENDS_PATH]"
        routerLinkActive="-active"
    >
      <ms-text weight="600" fontSize="13px">TRENDS</ms-text>
    </div>
  </div>

  <div class="flex align-center">
    <span
        class="action-icon export-to-pdf pi pi-file-pdf"
        pTooltip="Export page to PDF"
        tooltipPosition="bottom"
        positionTop="10"
        (click)="exportPageToPDF()"
    ></span>

    <span
        class="action-icon color-theme pi pi-{{ currentColorTheme === 'light' ? 'moon' : 'sun' }}"
        pTooltip="Change color theme"
        tooltipPosition="bottom"
        positionTop="10"
        (click)="changeColorTheme()"
    ></span>

    <img
        class="user-avatar"
        width="45"
        height="45"
        [src]="(currentUser$ | async)?.avatar || '/assets/images/avatar-default.png'"
        (click)="userPanel.toggle($event)"
    />

    <p-overlayPanel #userPanel>
      <div class="flex flex-column">
        <div class="dropdown-item-container flex align-center">
          <ms-text
              class="dropdown-item"
              weight="300"
              [routerLink]="[routesHelper.ROOT_PATH, routesHelper.EDIT_PROFILE_PATH]"
              (click)="userPanel.hide()"
          >
            Edit Profile
          </ms-text>
        </div>

        <div class="dropdown-item-container flex align-center">
          <ms-text
              class="dropdown-item"
              weight="300"
              [routerLink]="[routesHelper.ROOT_PATH, routesHelper.CHANGE_PASSWORD_PATH]"
              (click)="userPanel.hide()"
          >
            Change Password
          </ms-text>
        </div>

        <div class="dropdown-item-container flex align-center">
          <ms-text
              class="dropdown-item"
              weight="300"
              (click)="logout()"
          >
            Sign Out
          </ms-text>
        </div>
      </div>
    </p-overlayPanel>
  </div>
</div>
