import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { TextComponent } from '../../../ui/components/text/text.component';
import { AuthService } from '../../../core/services/auth.service';
import { AuthApiService } from '../../../core/api/auth.api.service';
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IApiResponse } from '../../../core/interfaces/api-response.interface';
import { Router } from '@angular/router';
import { RoutesHelper } from '../../../core/helpers/routes.helper';
import { Button } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { EKeyCode } from '../../../core/enums/key-code.enum';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextComponent,
    ReactiveFormsModule,
    Button,
    InputTextModule,
    CardModule,
  ],
})
export class AuthPageComponent {
  loginForm = new FormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private router: Router,
    private authService: AuthService,
    private authApiService: AuthApiService,
  ) {}

  @HostListener('window:keyup', ['$event'])
  handleKeyUp(event: any): void {
    if (event.keyCode !== EKeyCode.ENTER) {
      return;
    }

    this.login();
  }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.authApiService.login(this.loginForm.value as any)
      .pipe(take(1))
      .subscribe(this.handleLogin, this.handleError);
  }

  private handleLogin = (response: IApiResponse): void => {
    this.authService.saveAuthData(response);
    void this.router.navigate([RoutesHelper.DASHBOARD_PATH]);
  }

  private handleError = (error: HttpErrorResponse): void => {
  }
}
