import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'dashboard-brand-trends',
  templateUrl: './dashboard-brand-trends.component.html',
  styleUrl: './dashboard-brand-trends.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective],
})
export class DashboardBrandTrendsComponent {
  chartOptions: any = {
    textStyle: {
      color: '#B9B8CE',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let content = `${params[0].axisValue}<br/>`;
        params.forEach((item: any) => {
          content += `<div style="color:${item.color}">●</div> ${item.seriesName}: ${item.data}<br/>`;
        });
        return content;
      },
    },
    legend: {
      data: ['Apple', 'Samsung', 'Microsoft', 'Google', 'Sony'],  // Реальные бренды
      textStyle: {
        color: '#B9B8CE',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
      },
    },
    series: [
      {
        name: 'Apple', // Данные для Apple
        type: 'line',
        stack: 'Total',
        data: [20, 35, 50, 60, 65, 70, 75, 80, 85, 90, 95, 100],
        color: UiService.CHARTS_COLORS[0],
      },
      {
        name: 'Samsung', // Данные для Samsung
        type: 'line',
        stack: 'Total',
        data: [30, 45, 60, 70, 80, 85, 90, 95, 100, 105, 110, 120],
        color: UiService.CHARTS_COLORS[1],
      },
      {
        name: 'Microsoft', // Данные для Microsoft
        type: 'line',
        stack: 'Total',
        data: [25, 40, 55, 65, 75, 80, 85, 90, 95, 100, 105, 110],
        color: UiService.CHARTS_COLORS[2],
      },
      {
        name: 'Google', // Данные для Google
        type: 'line',
        stack: 'Total',
        data: [15, 30, 45, 55, 60, 65, 70, 75, 80, 85, 90, 95],
        color: UiService.CHARTS_COLORS[3],
      },
      {
        name: 'Sony', // Данные для Sony
        type: 'line',
        stack: 'Total',
        data: [10, 25, 40, 50, 55, 60, 65, 70, 75, 80, 85, 90],
        color: UiService.CHARTS_COLORS[4],
      },
    ],
  };
}
