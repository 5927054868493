import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { TextComponent } from '../../ui/components/text/text.component';
import { MessageBusService } from '../../core/services/message-bus.service';
import { ChangePageTitleMessage } from '../../core/messages/change-page-title.message';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UiService } from '../../core/services/ui.service';
import { AsyncPipe } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import jsPDF from 'jspdf';
import html2canvas, { Options } from 'html2canvas';
import { SpinnerModel } from '../../core/models/spinner.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextComponent,
    RouterLink,
    RouterLinkActive,
    AsyncPipe,
    OverlayPanelModule,
    TooltipModule
  ]
})
export class HeaderComponent implements OnInit {
  pageTitle = '';
  currentColorTheme!: 'light' | 'dark';
  currentUser$ = this.authService.currentUser$;

  readonly routesHelper = RoutesHelper;

  private get authorizedLayoutRootElement(): HTMLElement {
    return document.getElementById('authorized-layout-root')!;
  }

  private get authorizedLayoutContentElement(): HTMLElement {
    return document.getElementById('authorized-layout-content')!;
  }

  private get htmlToCanvasOptions(): Partial<Options> {
    return {
      backgroundColor: this.currentColorTheme === 'light' ? '#FFFFFF' : '#1E1E2F',
      scale: 2,
      scrollX: 0,
      scrollY: 0,
      height: this.authorizedLayoutRootElement.scrollHeight,
      windowHeight: this.authorizedLayoutRootElement.scrollHeight,
      useCORS: true,
    };
  }

  constructor(
    public authService: AuthService,
    private renderer: Renderer2,
    private router: Router,
    private dr: DestroyRef,
    private messageBusService: MessageBusService,
    private uiService: UiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.messageBusService.of(ChangePageTitleMessage)
      .pipe(takeUntilDestroyed(this.dr))
      .subscribe(this.changePageTitle);

    this.currentColorTheme = this.uiService.getUserPreferences()?.colorTheme ?? 'dark';
  }

  changeColorTheme(): void {
    const colorThemeToSet = this.currentColorTheme === 'light' ? 'dark' : 'light';
    this.currentColorTheme = colorThemeToSet;
    this.uiService.setColorTheme(colorThemeToSet);
  }

  exportPageToPDF(): void {
    this.renderer.setStyle(this.authorizedLayoutContentElement, 'overflow', 'visible');
    html2canvas(
      this.authorizedLayoutRootElement,
      this.htmlToCanvasOptions,
    ).then((canvas: HTMLCanvasElement): void => {
      const imgWidth = 208; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      let position = 0;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add more pages if the content is longer than one page
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(`Mysterya.${this.pageTitle}.pdf`);
      this.renderer.removeStyle(this.authorizedLayoutContentElement, 'overflow');
    });
  }

  logout(): void {
    this.authService.logout();
    void this.router.navigate([RoutesHelper.ROOT_PATH, RoutesHelper.AUTH_PATH]);
  }

  private changePageTitle = (message: ChangePageTitleMessage): void => {
    this.pageTitle = message.pageTitle;
  };
}
