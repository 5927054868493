<div class="trends-page-root">
  <div class="row">
    <group-info />
  </div>

  <div class="row">
    <p-card>
      <trends-filters [form]="trendsFilterForm" [brands]="brands" [cities]="cities" [countries]="countries" [locations]="locations" />
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Brand <ms-text weight="700">Trends</ms-text>
      </ms-text>
      <trends-brands-trends [data]="trendsChartData" />
    </p-card>
  </div>
</div>
