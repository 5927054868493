<div class="gallery-page-root">
  <div class="row">
    <p-card>
      <gallery-filters [form]="galleryFilterForm" [cities]="cities" [countries]="countries" [locations]="locations" />
    </p-card>
  </div>

  <div class="images-gallery-container">
    @for (gallery of galleries; track gallery._id) {
      <gallery-preview [gallery]="gallery" />
    }
  </div>
</div>
