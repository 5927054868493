import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import {
  IGroupsFullReportTouchpoint
} from '../../../../../models/groups-full-report/groups-full-report-touchpoint.interface';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'dashboard-top-touchpoints',
  templateUrl: './dashboard-top-touchpoints.component.html',
  styleUrl: './dashboard-top-touchpoints.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxEchartsDirective],
  providers: [provideEcharts()],
})
export class DashboardTopTouchpointsComponent implements OnChanges {
  @Input() touchPoints!: IGroupsFullReportTouchpoint[];

  chartOptions!: any;

  ngOnChanges(changes: SimpleChanges): void {
    const { touchPoints } = changes;
    if (touchPoints?.currentValue && touchPoints?.currentValue !== touchPoints?.previousValue) {
      if (!this.touchPoints.length) {
        return;
      }

      this.chartOptions = this.buildChartOptions(this.touchPoints);
    }
  }

  private buildChartOptions(touchPoints: IGroupsFullReportTouchpoint[]): any {
    return {
      textStyle: {
        color: '#B9B8CE',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      legend: {
        data: [...touchPoints.map((touchPoint: IGroupsFullReportTouchpoint) => touchPoint.name)],
        textStyle: {
          color: '#B9B8CE',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 'normal',
        },
      },
      radar: {
        indicator: touchPoints.map((touchPoint: IGroupsFullReportTouchpoint) => ({
          name: touchPoint.name, max: 100,
        })),
        radius: '65%'
      },
      series: [
        {
          name: 'Actual vs Average',
          type: 'radar',
          data: touchPoints.map((touchPoint: IGroupsFullReportTouchpoint, index: number) => ({
            value: [index === 0 ? touchPoint.avg : 0, index === 1 ? touchPoint.avg : 0, index === 2 ? touchPoint.avg : 0],
            name: touchPoint.name,
          })),
          itemStyle: {
            color: (param: any) => UiService.CHARTS_COLORS[param.dataIndex],
          },
        },
      ]
    };
  }
}
