<div class="group-info-root">
  @if (currentActiveGroup$ | async; as currentActiveGroup) {
    <img
        class="group-logo"
        width="70"
        height="70"
        [src]="currentActiveGroup.image"
    />

    <div class="group-info">
      <ms-text weight="300" color="#9a9a9a" fontSize="18px">{{ currentActiveGroup.name }}</ms-text>
      <div class="brands-container flex flex-column">
        @for (connectedBrand of currentActiveGroup.connected_brands; track connectedBrand._id; let last = $last) {
          <ms-text class="brand-name" weight="300" fontSize="14px">{{ connectedBrand.brand_id.name }}{{ last ? '' : ',' }}</ms-text>
        }
      </div>
    </div>

    <span class="pi pi-sort-down-fill panel-trigger" (click)="groupsPanel.toggle($event)"></span>
    <p-overlayPanel #groupsPanel>
      <div class="flex flex-column">
        @for (group of userGroups$ | async; track group._id) {
          <ms-text
              class="dropdown-group-name"
              weight="300"
              [class.-active]="group._id === currentActiveGroup._id"
              (click)="setActiveGroup(group, currentActiveGroup)"
          >
            {{ group.name }}
          </ms-text>
        }
      </div>
    </p-overlayPanel>
  } @else {
    <p-progressSpinner ariaLabel="loading" />
  }
</div>
