<div class="gallery-info-root flex flex-column">
  <img class="gallery-info-image" [src]="config.data!.file" alt="" />

  <div class="question-row question-content flex align-center">
    <ms-text class="row-title" weight="300" color="#9a9a9a" fontSize="16px">Question: </ms-text>
    <ms-text color="#ffffff" fontSize="14px">{{ questionText }}</ms-text>
  </div>

  <div class="question-row admin-comment flex align-center">
    <ms-text class="row-title" weight="300" color="#9a9a9a" fontSize="16px">Admin comment: </ms-text>
    <ms-text color="#ffffff" fontSize="14px">{{ config.data!.gallery.admincomment }}</ms-text>
  </div>

  <div class="question-row user-answer flex align-center">
    <ms-text class="row-title" weight="300" color="#9a9a9a" fontSize="16px">Answer: </ms-text>
    <ms-text color="#ffffff" fontSize="14px">{{ config.data!.gallery.comment }}</ms-text>
  </div>
</div>
