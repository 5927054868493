import { Directive, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appUploadFile]',
  standalone: true,
})
export class UploadFileDirective implements OnInit {
  @Input() multiple!: boolean;
  @Input() onlyImages? = false;
  @Input() onlyChatAttachment? = false;
  @Input() delegateFileTypeCheck? = false;
  @Input() disable!: boolean;
  @Output() select = new EventEmitter<File[]>();

  private inputElement!: HTMLInputElement;

  constructor(
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.inputElement = this.renderer.createElement('input');
    this.inputElement.setAttribute('type', 'file');
    this.inputElement.multiple = this.multiple;
    this.renderer.listen(this.inputElement, 'change', this.onFileSelected);
  }

  @HostListener('click')
  click(): void {
    if (!this.disable) {
      this.inputElement.click();
    }
  }

  private onFileSelected = (e: Event): void => {
    this.select.emit(Array.from((e.target as any).files));
    this.inputElement.value = '';
  };
}
