import { Injectable } from '@angular/core';
import { IApiResponse } from '../interfaces/api-response.interface';
import { IUser } from '../../models/user/user.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly currentUser$ = new BehaviorSubject<IUser | null>(null);

  get authData(): null | any {
    const currentUser = this.currentUser$.getValue();
    const authData = JSON.parse(localStorage.getItem('ms-auth-data')!);
    if (!currentUser && !authData) {
      return null;
    }

    if (authData) {
      this.currentUser$.next(authData);
    }

    return currentUser || authData;
  }

  get connectedGroups(): any {
    return this.authData?.connected_groups;
  }

  clearAuthData(): void {
    localStorage.removeItem('ms-auth-data');
  }

  saveAuthData(authData: IApiResponse<IUser>): void {
    localStorage.setItem(
      'ms-auth-data',
      JSON.stringify(authData.data)
    );
    this.currentUser$.next(authData.data);
  }

  logout(): void {
    this.clearAuthData();
  }
}
