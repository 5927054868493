import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IReportGroup } from '../../models/report-groups/report-group.interface';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  userGroups = new BehaviorSubject<IReportGroup[]>([]);
  currentActiveGroup = new BehaviorSubject<IReportGroup | null>(null);
}
