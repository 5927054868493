import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { TextComponent } from '../../../../../ui/components/text/text.component';
import { TrendsBrandsTrendsComponent } from '../../parts/trends-brands-trends/trends-brands-trends.component';
import { TrendsFiltersComponent } from '../../parts/trends-filters/trends-filters.component';
import { GroupInfoComponent } from '../../../../../ui/components/group-info/group-info.component';
import { MessageBusService } from '../../../../../core/services/message-bus.service';
import { ChangePageTitleMessage } from '../../../../../core/messages/change-page-title.message';
import {
  DashboardBrandIndicatorsComponent
} from '../../../../dashboard/components/parts/dashboard-brand-indicators/dashboard-brand-indicators.component';
import { CardModule } from 'primeng/card';
import { take } from 'rxjs';
import { IApiResponse } from '../../../../../core/interfaces/api-response.interface';
import { IBrandsChartByGroup } from '../../../../../models/trends/brands-chart-by-group.interface';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IReportGroup } from '../../../../../models/report-groups/report-group.interface';
import { filter } from 'rxjs/operators';
import { GroupsService } from '../../../../../core/services/groups.service';
import { ReportApiService } from '../../../../../core/api/report.api.service';

@Component({
  selector: 'app-trends-page',
  templateUrl: './trends-page.component.html',
  styleUrl: './trends-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DashboardBrandIndicatorsComponent,
    TextComponent,
    TrendsBrandsTrendsComponent,
    TrendsFiltersComponent,
    GroupInfoComponent,
    CardModule
  ],
})
export class TrendsPageComponent implements OnInit {
  brands: any[] = [];
  cities: string[] = [];
  countries: string[] = [];
  locations: string[] = [];
  trendsChartData!: IBrandsChartByGroup;
  currentActiveGroup!: IReportGroup;
  trendsFilterForm = new UntypedFormGroup({
    brand: new FormControl<string[]>([]),
    channels: new FormControl<string[]>([]),
    city: new FormControl<string[]>([]),
    country: new FormControl<string[]>([]),
    outlet: new FormControl<string[]>([]),
    period: new FormControl<Date[]>([]),
    year: new FormControl(),
  });

  constructor(
    private dr: DestroyRef,
    private cdr: ChangeDetectorRef,
    private groupsService: GroupsService,
    private messageBusService: MessageBusService,
    private reportsApiService: ReportApiService,
  ) {}

  ngOnInit(): void {
    this.messageBusService.send(new ChangePageTitleMessage('TRENDS'));
    this.reportsApiService.fetchActiveGroup().pipe(take(1)).subscribe(this.handleActiveGroups);

    this.groupsService.currentActiveGroup.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.dr),
    ).subscribe(this.getActiveGroupDependentData);
    this.trendsFilterForm.valueChanges.pipe(
      takeUntilDestroyed(this.dr),
    ).subscribe(this.handleFilterChanged);
  }

  private handleActiveGroups = (response: IApiResponse<IReportGroup[]>): void => {
    this.groupsService.userGroups.next(response.data);
    this.groupsService.currentActiveGroup.next(response.data[0]);
  }

  private getActiveGroupDependentData = (activeGroup: IReportGroup): void => {
    this.currentActiveGroup = activeGroup;
    this.getBrandsChartByGroup(activeGroup, {});
    this.reportsApiService.getFilterCity(activeGroup._id).subscribe(_ => this.cities = _.data);
    this.reportsApiService.getFilterCountry(activeGroup._id).subscribe(_ => this.countries = _.data);
    this.reportsApiService.getFilterLocation(activeGroup._id).subscribe(_ => this.locations = _.data);
    this.brands = activeGroup.connected_brands.map(_ => ({
      label: _.brand_id.name,
      value: _.brand_id._id
    }));
  }

  private handleFilterChanged = (filterValue: any): void => {
    const [from, to] = (filterValue.period || []);

    delete filterValue.period;
    this.getBrandsChartByGroup(this.currentActiveGroup, { ...filterValue, from: this.formatDate(from), to: this.formatDate(to) });
  }

  private getBrandsChartByGroup(activeGroup: IReportGroup, filter: any): void {
    this.reportsApiService.getBrandsChartByGroup(activeGroup._id, filter).pipe(take(1)).subscribe(this.handleBrandsChartData);
  }

  private handleBrandsChartData = (response: IApiResponse<IBrandsChartByGroup>): void => {
    this.trendsChartData = response.data;
    this.cdr.detectChanges();
  }

  private formatDate(date: Date | null): string | null {
    if (!date) {
      return null;
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}
