import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'gallery-filters',
  templateUrl: './gallery-filters.component.html',
  styleUrl: './gallery-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DropdownModule,
    CalendarModule,
    FloatLabelModule,
    CascadeSelectModule,
    FormsModule,
    MultiSelectModule,
    ReactiveFormsModule,
  ]
})
export class GalleryFiltersComponent {
  @Input() form!: UntypedFormGroup;
  @Input() cities!: string[];
  @Input() countries!: string[];
  @Input() locations!: string[];
}
