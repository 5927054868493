import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import {
  IGroupsFullReportPainPoints
} from '../../../../../models/groups-full-report/groups-full-report-pain-points.interface';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'dashboard-points-card',
  templateUrl: './dashboard-points-card.component.html',
  styleUrl: './dashboard-points-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective],
})
export class DashboardPointsCardComponent implements OnChanges {
  @Input() pointsData!: IGroupsFullReportPainPoints[];

  chartOptions!: any;

  ngOnChanges(changes: SimpleChanges): void {
    const { pointsData } = changes;
    if (pointsData?.currentValue && pointsData?.currentValue !== pointsData?.previousValue) {
      if (!this.pointsData.length) {
        return;
      }

      this.chartOptions = this.buildChartOptions(this.pointsData);
    }
  }

  private buildChartOptions(data: any[]): any {
    return {
      textStyle: {
        color: '#B9B8CE',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      dataset: {
        source: [
          ['score', 'amount', 'name'], // Заголовки колонок для данных
          ...data.map((point: any) => (
            [Math.floor(Math.random() * 100), point.avg, point.name] // Случайный показатель для "score"
          )),
        ],
      },
      grid: { containLabel: true },
      xAxis: { name: 'Amount' },
      yAxis: { type: 'category' },
      series: [
        {
          type: 'bar',
          encode: {
            x: 'amount',  // Привязка данных для оси X
            y: 'name',    // Привязка данных для оси Y
          },
          itemStyle: {
            color: (param: any) => UiService.CHARTS_COLORS[param.dataIndex], // Разноцветные бары
          },
        },
      ],
      color: UiService.CHARTS_COLORS, // Цвета из конфигурации
    };
  }
}
