import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UiService } from '../../../../../core/services/ui.service';
import { Button } from 'primeng/button';
import { CardModule } from 'primeng/card';
import {
  ChangePasswordFormComponent
} from '../../../../change-password/components/parts/change-password-form/change-password-form.component';
import { EditProfileFormComponent } from '../../parts/edit-profile-form/edit-profile-form.component';
import { AuthApiService } from '../../../../../core/api/auth.api.service';
import { IUser } from '../../../../../models/user/user.interface';
import { mergeMap, of, switchMap, take } from 'rxjs';
import { AuthService } from '../../../../../core/services/auth.service';
import { IApiResponse } from '../../../../../core/interfaces/api-response.interface';

@Component({
  selector: 'edit-profile-page',
  templateUrl: './edit-profile-page.component.html',
  styleUrl: './edit-profile-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    Button,
    CardModule,
    ChangePasswordFormComponent,
    EditProfileFormComponent
  ]
})
export class EditProfilePageComponent implements OnInit {
  user!: IUser;
  editProfileForm = this.patchFormWithUserData();

  constructor(
    private cdr: ChangeDetectorRef,
    private uiService: UiService,
    private authService: AuthService,
    private authApiService: AuthApiService,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.authData;
    if (this.user) {
      this.editProfileForm = this.patchFormWithUserData(this.user);
      this.cdr.markForCheck();

      return;
    }

    this.authApiService.getCurrentUser().subscribe((user: IUser) => {
      this.user = user;
      this.editProfileForm = this.patchFormWithUserData(user)
      this.cdr.markForCheck();
    });
  }

  rollbackUserAvatar(): void {
    this.editProfileForm.get('avatar')?.setValue(this.user.avatar);
    this.cdr.markForCheck();
  }

  updateUserProfile(): void {
    if (!this.editProfileForm.valid) {
      this.editProfileForm.markAsDirty();
      this.editProfileForm.markAsTouched();
      this.cdr.markForCheck();

      return;
    }

    const avatar = this.editProfileForm.get('avatar')!.value;
    const request = this.user.avatar
      ? of
      : avatar
        ? this.authApiService.uploadFile
        : of;
    const payload = { ...this.user, ...this.editProfileForm.getRawValue() };
    delete payload.email;
    request(avatar).pipe(
      switchMap((response: IApiResponse<any> | null) => this.authApiService.updateProfile(
        { ...payload, avatar: response?.data?.[0]?.location || payload.avatar })
      )
    ).pipe(take(1)).subscribe(this.handleProfileUpdated);
  }

  private patchFormWithUserData(user?: IUser): UntypedFormGroup {
    return new UntypedFormGroup({
      avatar: new FormControl(user?.avatar),
      first_name: new FormControl(user?.first_name, [Validators.required]),
      last_name: new FormControl(user?.last_name, [Validators.required]),
      email: new FormControl({ value: user?.email, disabled: !!user?.email }, [Validators.required]),
      phoneNumber: new FormControl(user?.phoneNumber, [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
      age: new FormControl(user?.age, [Validators.required]),
    });
  }

  private handleProfileUpdated = (): void => {
    this.uiService.toast$.next({
      severity: 'success',
      summary: 'Success',
      detail: 'Profile has been updated successfully.'
    });
  }
}
