import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { TextComponent } from '../../../ui/components/text/text.component';

@Component({
  selector: 'no-data-block',
  templateUrl: './no-data-block.component.html',
  styleUrl: './no-data-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextComponent
  ]
})
export class NoDataBlockComponent {
  @HostBinding('class.no-data-block') readonly baseClass = true;
}
