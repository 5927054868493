<div class="dashboard-audit-card-root">
  <div class="audit-type-column">
    <ms-text
        class="card-title"
        weight="300"
        color="#9a9a9a"
        fontSize="20px"
    >
      {{ auditType }}
    </ms-text>
    <ms-text color="#fff" fontSize="24px">{{ audit.total || 0 }}</ms-text>
  </div>
  <div class="audit-info-column">
    <div class="audit-info-row">
      <ms-text color="#fff">{{ audit.total_brand || 0 }}</ms-text>
      <ms-text color="#9a9a9a">Brands</ms-text>
    </div>

    <div class="audit-info-row">
      <ms-text color="#fff">{{ audit.total_country || 0 }}</ms-text>
      <ms-text color="#9a9a9a">Countries</ms-text>
    </div>

    <div class="audit-info-row">
      <ms-text color="#fff">{{ audit.total_city || 0 }}</ms-text>
      <ms-text color="#9a9a9a">Cities</ms-text>
    </div>

    <div class="audit-info-row">
      <ms-text color="#fff">{{ audit.total_location || 0 }}</ms-text>
      <ms-text color="#9a9a9a">Locations</ms-text>
    </div>
  </div>
</div>
