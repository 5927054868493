import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { IQuestionByIndicatorFilters } from '../../../../../models/questions/question-by-indicator-filters.interface';
import { NoDataBlockComponent } from '../../../../../core/components/no-data-block/no-data-block.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'questions-chart',
  templateUrl: './questions-chart.component.html',
  styleUrl: './questions-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective, NoDataBlockComponent, ProgressSpinnerModule],
})
export class QuestionsChartComponent implements OnChanges {
  @Input() data?: IQuestionByIndicatorFilters[];

  chartOptions!: any;

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;
    if (data?.currentValue && data?.currentValue !== data?.previousValue) {
      this.chartOptions = this.buildChartOptions(this.data!);
    }
  }

  buildChartOptions(data: IQuestionByIndicatorFilters[]): any {
    return {
      textStyle: {
        color: '#B9B8CE',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      dataset: {
        source: [
          ['score', 'percent', 'product'],
          ...data.map((question: IQuestionByIndicatorFilters) => (
            [Math.floor(Math.random() * 100), question.usepercent, question.question.split(':')?.[0]]
          )),
        ]
      },
      grid: { containLabel: true },
      xAxis: { name: 'percent' },
      yAxis: { type: 'category' },
      series: [
        {
          type: 'bar',
          encode: {
            x: 'percent',
            y: 'product'
          },
          itemStyle: {
            color: (param: any) => UiService.CHARTS_COLORS[param.dataIndex],
          },
        }
      ],
      color: UiService.CHARTS_COLORS,
    };
  }
}
