import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApiResponse } from '../interfaces/api-response.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../env/environment';
import { IGroupsFullReport } from '../../models/groups-full-report/groups-full-report.interface';
import { IBrandsChartByGroup } from '../../models/trends/brands-chart-by-group.interface';
import { IComparisonChartData } from '../../models/comparison/comparison-chart-data.interface';
import { IQuestionByIndicatorFilters } from '../../models/questions/question-by-indicator-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportApiService {
  constructor(
    private http: HttpClient,
  ) {}

  getFilterCity(groupId: any): Observable<IApiResponse<string[]>> {
    return this.http.get<IApiResponse<string[]>>(`${environment.apiUrl}/analytic/report/groups/city/${groupId}`, { params: { group: groupId } });
  }

  getFilterCountry(groupId: any): Observable<IApiResponse<string[]>> {
    return this.http.get<IApiResponse<string[]>>(`${environment.apiUrl}/analytic/report/groups/country/${groupId}`);
  }

  getFilterLocation(groupId: any): Observable<IApiResponse<string[]>> {
    return this.http.get<IApiResponse<string[]>>(`${environment.apiUrl}/analytic/report/groups/location/${groupId}`);
  }

  getFullReport(groupId: string, filter: object): Observable<IApiResponse<IGroupsFullReport>> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/analytic/report/groups/fullreport/${groupId}`, filter);
  }

  fetchActiveGroup(): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/analytic/report/groups`);
  }

  fetchBrandJourneyActiveStage(stageId: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/analytic/report/groups/touchpoint?stageId=${stageId}`, {});
  }

  getBrandsChartByGroup(groupId: string, filter: object): Observable<IApiResponse<IBrandsChartByGroup>> {
    return this.http.post<IApiResponse<IBrandsChartByGroup>>(`${environment.apiUrl}/analytic/brands-chart-by-group/${groupId}`, filter);
  }

  getComparisonChartFilters(): Observable<IApiResponse<any>> {
    return this.http.get<IApiResponse<any>>(`${environment.apiUrl}/analytic/comparision/filter`);
  }

  getComparisonChart(filter: any): Observable<IApiResponse<IComparisonChartData>> {
    return this.http.post<IApiResponse<IComparisonChartData>>(`${environment.apiUrl}/analytic/comparision-chart`, filter);
  }

  getQuestionsByIndicatorFilters(activeGroupId: string, filter: any): Observable<IApiResponse<IQuestionByIndicatorFilters[]>> {
    return this.http.post<IApiResponse<IQuestionByIndicatorFilters[]>>(`${environment.apiUrl}/analytic/questions/by-indicators-filter`, activeGroupId);
  }
}
