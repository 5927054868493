<div class="change-password-form-root flex flex-column" [formGroup]="form">
  <p-floatLabel>
    <p-password
        id="old_password"
        formControlName="old_password"
        [feedback]="false"
        [toggleMask]="true"
    />
    <label for="old_password">Old Password</label>
  </p-floatLabel>

  <p-floatLabel>
    <p-password
        id="password"
        formControlName="password"
        [toggleMask]="true"
    />
    <label for="password">Password</label>
  </p-floatLabel>

  <p-floatLabel>
    <p-password
        id="confirm_password"
        formControlName="confirm_password"
        [feedback]="false"
        [toggleMask]="true"
    />
    <label for="confirm_password">Confirm Password</label>
  </p-floatLabel>

  <ng-content />
</div>
