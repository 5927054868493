import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditorModule } from 'primeng/editor';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule, UploadEvent } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { TextComponent } from '../../../../../ui/components/text/text.component';

@Component({
  selector: 'dashboard-overall-recommendations',
  templateUrl: './dashboard-overall-recommendations.component.html',
  styleUrl: './dashboard-overall-recommendations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    EditorModule,
    ReactiveFormsModule,
    InputTextModule,
    ToastModule,
    FileUploadModule,
    TextComponent,
  ],
  providers: [
    MessageService,
  ],
})
export class DashboardOverallRecommendationsComponent {
  form = new FormGroup({
    title: new FormControl(null),
    text: new FormControl(null),
    files: new FormControl(null),
  });

  constructor(private messageService: MessageService) {}

  onUpload(event: UploadEvent): void {
    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded Successfully' });
  }
}
