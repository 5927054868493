<div class="trends-filters-root" [formGroup]="form">
  <p-multiSelect
      placeholder="Brand"
      formControlName="brand"
      [options]="brands"
      [showHeader]="false"
  />

  <p-multiSelect
      placeholder="Experiences"
      formControlName="channels"
      [options]="['Online', 'Offline', 'Online & Offline']"
      [showHeader]="false"
  />

  <p-multiSelect
      placeholder="Country"
      formControlName="country"
      [options]="countries"
      [showHeader]="false"
  />

  <p-multiSelect
      placeholder="City"
      formControlName="city"
      [options]="cities"
      [showHeader]="false"
  />

  <p-multiSelect
      placeholder="Location"
      formControlName="outlet"
      [options]="locations"
      [showHeader]="false"
  />

  <p-floatLabel>
    <p-calendar
        id="dateRange"
        dateFormat="dd.mm.yy"
        selectionMode="range"
        formControlName="period"
        [readonlyInput]="true"
        [showButtonBar]="true"
    />
    <label for="dateRange">From - To</label>
  </p-floatLabel>
</div>
