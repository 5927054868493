import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'questions-filters',
  templateUrl: './questions-filters.component.html',
  styleUrl: './questions-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DropdownModule,
    CalendarModule,
    FloatLabelModule,
    ReactiveFormsModule,
    MultiSelectModule
  ]
})
export class QuestionsFiltersComponent {
  @Input() form!: UntypedFormGroup;
  @Input() brands!: any[];
  @Input() cities!: string[];
  @Input() countries!: string[];
  @Input() locations!: string[];
}
