import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { ChangePageTitleMessage } from '../../../../../core/messages/change-page-title.message';
import { MessageBusService } from '../../../../../core/services/message-bus.service';
import { GalleryPreviewComponent } from '../../parts/gallery-preview/gallery-preview.component';
import { CardModule } from 'primeng/card';
import { GalleryFiltersComponent } from '../../parts/gallery-filters/gallery-filters.component';
import { GalleryApiService } from '../../../../../core/api/gallery.api.service';
import { take } from 'rxjs';
import { IApiResponse } from '../../../../../core/interfaces/api-response.interface';
import { IGallery } from '../../../../../models/gallery/gallery.interface';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import {
  DashboardFiltersComponent
} from '../../../../dashboard/components/parts/dashboard-filters/dashboard-filters.component';
import { ReportApiService } from '../../../../../core/api/report.api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { IReportGroup } from '../../../../../models/report-groups/report-group.interface';
import { GroupsService } from '../../../../../core/services/groups.service';

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrl: './gallery-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GalleryPreviewComponent,
    CardModule,
    GalleryFiltersComponent,
    DashboardFiltersComponent,
  ]
})
export class GalleryPageComponent implements OnInit {
  cities: string[] = [];
  countries: string[] = [];
  locations: string[] = [];
  galleries: IGallery[] = [];
  currentActiveGroup!: IReportGroup;
  galleryFilterForm = new UntypedFormGroup({
    datesRange: new FormControl<Date[]>([]),
    city: new FormControl<string[]>([]),
    country: new FormControl<string[]>([]),
    outlet: new FormControl<string[]>([]),
    channels: new FormControl<string[]>([]),
    touchPoints: new FormControl<string[]>([]),
    ratings: new FormControl<string[]>([]),
  });

  constructor(
    private dr: DestroyRef,
    private cdr: ChangeDetectorRef,
    private groupsService: GroupsService,
    private messageBusService: MessageBusService,
    private galleryApiService: GalleryApiService,
    private reportApiService: ReportApiService,
  ) {}

  ngOnInit(): void {
    this.messageBusService.send(new ChangePageTitleMessage('GALLERY'));
    this.reportApiService.fetchActiveGroup().pipe(take(1)).subscribe(this.handleActiveGroups);
    this.subscribeToChanges();
  }

  private subscribeToChanges(): void {
    this.galleryFilterForm.valueChanges.pipe(
      takeUntilDestroyed(this.dr),
    ).subscribe(this.handleFilterChanged);
    this.groupsService.currentActiveGroup.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.dr),
    ).subscribe(this.getActiveGroupDependentData);
  }

  private getActiveGroupDependentData = (activeGroup: IReportGroup): void => {
    this.currentActiveGroup = activeGroup;
    this.getGalleries(activeGroup, {});
    this.reportApiService.getFilterCity(activeGroup._id).subscribe(_ => this.cities = _.data);
    this.reportApiService.getFilterCountry(activeGroup._id).subscribe(_ => this.countries = _.data);
    this.reportApiService.getFilterLocation(activeGroup._id).subscribe(_ => this.locations = _.data);
  }

  private handleFilterChanged = (filterValue: any): void => {
    const [from, to] = (filterValue.datesRange || []);

    delete filterValue.datesRange;
    this.getGalleries(this.currentActiveGroup, { ...filterValue, from: this.formatDate(from), to: this.formatDate(to) });
  }

  private getGalleries(activeGroup: IReportGroup, filter: any): void {
    this.galleryApiService.getGalleries(activeGroup._id, filter).pipe(take(1)).subscribe(this.handleGalleries);
  }

  private handleGalleries = (res: IApiResponse<IGallery[]>): void => {
    this.galleries = res.data;
    this.cdr.detectChanges();
  };

  private handleActiveGroups = (response: IApiResponse<IReportGroup[]>): void => {
    this.groupsService.userGroups.next(response.data);
    this.groupsService.currentActiveGroup.next(response.data[0]);
  }

  private formatDate(date: Date | null): string | null {
    if (!date) {
      return null;
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}
