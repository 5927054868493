<div class="app-page-not-found">
  <img
      class="page-not-found-icon"
      src="/assets/svg/page-not-found.svg"
      alt="No data"
  />

  <ms-text
      class="page-not-found-text"
      color="#FFFFFF"
      fontSize="36px"
  >
    Page not found
  </ms-text>

  <p-button
      label="Proceed to Reports"
      [raised]="true"
      [routerLink]="[routesHelper.ROOT_PATH, routesHelper.DASHBOARD_PATH]"
  />
</div>
