import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import { UiService } from '../../../core/services/ui.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IUserPreferences } from '../../../core/interfaces/user-preferences.interface';

@Component({
  selector: 'ms-text',
  template: `
    <ng-content></ng-content>
  `,
  styleUrl: './text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TextComponent implements OnInit {
  private static FONT_WEIGHT_PREFIX = 'ms-text-';

  @Input() weight: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' = '500';
  @Input() color!: string;
  @Input() fontSize!: string;

  @HostBinding('class.ms-text') baseClass = true;

  constructor(
    private dr: DestroyRef,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private uiService: UiService,
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, `${TextComponent.FONT_WEIGHT_PREFIX}${this.weight}`);
    if (this.color) {
      this.elementRef.nativeElement.style.setProperty('color', this.color);
    }

    if (this.fontSize) {
      this.elementRef.nativeElement.style.setProperty('font-size', this.fontSize);
    }

    this.uiService.userPreferences
      .pipe(takeUntilDestroyed(this.dr))
      .subscribe(this.handleThemeChanged);
  }

  private handleThemeChanged = (userPreferences: IUserPreferences): void => {
    const isLightTheme = userPreferences?.colorTheme === 'light';
    if (!isLightTheme) {
      this.elementRef.nativeElement.style.setProperty('color', this.color ?? '#FFFFFF');

      return;
    }

    this.changeColorsForLightTheme();
  }

  private changeColorsForLightTheme(): void {
    if (!this.color || this.color.toLowerCase().includes('#fff')) {
      this.elementRef.nativeElement.style.setProperty('color', '#334155');
      return;
    }

    if (this.color.toLowerCase().includes('#9a9a9a')) {
      this.elementRef.nativeElement.style.setProperty('color', '#64748b');
      return;
    }
  }
}
