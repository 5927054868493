import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { IComparisonChartData } from '../../../../../models/comparison/comparison-chart-data.interface';
import { NoDataBlockComponent } from '../../../../../core/components/no-data-block/no-data-block.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { IBrandsChartSeries } from '../../../../../models/trends/brands-chart-series.interface';
import { UiService } from '../../../../../core/services/ui.service';

@Component({
  selector: 'comparison-chart',
  templateUrl: './comparison-chart.component.html',
  styleUrl: './comparison-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective, NoDataBlockComponent, ProgressSpinnerModule],
})
export class ComparisonChartComponent implements OnChanges {
  @Input() data?: IComparisonChartData;

  chartOptions!: any;

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;
    if (data?.currentValue && data?.currentValue !== data?.previousValue) {
      this.chartOptions = this.buildChartOptions(this.data!);
    }
  }

  private buildChartOptions(data: IComparisonChartData): any {
    return {
      textStyle: {
        color: '#B9B8CE',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      dataset: {
        source: [
          ['score', 'data', 'product'],
          ...data.series.map((item: IBrandsChartSeries) => (
            [Math.floor(Math.random() * 100), item.data, item.name]
          )),
        ]
      },
      grid: { containLabel: true },
      xAxis: { name: 'data' },
      yAxis: { type: 'category' },
      series: [
        {
          type: 'bar',
          encode: {
            x: 'data',
            y: 'product'
          },
          itemStyle: {
            color: (param: any) => UiService.CHARTS_COLORS[param.dataIndex],
          },
        }
      ],
      color: UiService.CHARTS_COLORS,
    };
  }
}
