import { EMessageChannel } from '../enums/message-channel.enum';
import { Message } from '../decorators/message.decorator';

@Message('ChangePageTitleMessage', EMessageChannel.BASE)
export class ChangePageTitleMessage {
  readonly name = 'ChangePageTitleMessage';
  readonly channel = EMessageChannel.BASE;

  constructor(
    public pageTitle: string,
  ) {}
}
