import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { Button } from 'primeng/button';
import { IUser } from '../../../../../models/user/user.interface';
import { UploadFileDirective } from '../../../../../core/directives/upload-file.directive';

@Component({
  selector: 'edit-profile-form',
  templateUrl: './edit-profile-form.component.html',
  styleUrl: './edit-profile-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FloatLabelModule,
    PasswordModule,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    Button,
    UploadFileDirective
  ]
})
export class EditProfileFormComponent {
  @Input() user!: IUser;
  @Input() form!: UntypedFormGroup;
  @Output() readonly undoAvatarChange = new EventEmitter<void>();

  avatarPreview?: string;

  get avatar(): string {
    return this.avatarPreview || this.form.get('avatar')!.value || '/assets/images/avatar-default.png';
  }

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  handleFileChanged(files: File[]): void {
    this.form.get('avatar')!.setValue(files[0]);
    this.buildImagePreview(files[0]);
  }

  private buildImagePreview(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.avatarPreview = reader.result as string;
      this.cdr.markForCheck();
    }
    reader.readAsDataURL(file);
  }
}
