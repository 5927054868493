import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RoutesHelper } from '../helpers/routes.helper';
import { UiService } from '../services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private uiService: UiService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authData = this.authService.authData;

    req = req.clone({
      headers: req.headers.set(
        "Authorization",
        `Bearer ${authData?.token}`
      ),
    });

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == HttpStatusCode.Unauthorized) {
          void this.router.navigate([RoutesHelper.AUTH_PATH]);
        } else {
          this.uiService.toast$.next({ severity: 'error', summary: 'Error', detail: err.error?.message || 'Error occurred' });
        }

        return throwError(err);
      })
    );
  }
}
