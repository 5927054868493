import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import {
  IGroupsFullReportSurveyTask
} from '../../../../../models/groups-full-report/groups-full-report-survey-task.interface';
import { UiService } from '../../../../../core/services/ui.service';
import { IGroupsFullReport } from '../../../../../models/groups-full-report/groups-full-report.interface';

@Component({
  selector: 'dashboard-tasks-performance',
  templateUrl: './dashboard-tasks-performance.component.html',
  styleUrl: './dashboard-tasks-performance.component.scss',
  standalone: true,
  providers: [provideEcharts()],
  imports: [NgxEchartsDirective],
})
export class DashboardTasksPerformanceComponent implements OnChanges {
  @Input() fullReport!: IGroupsFullReport;

  chartOptions: any;

  ngOnChanges(changes: SimpleChanges): void {
    const { fullReport } = changes;
    if (fullReport?.currentValue && fullReport?.currentValue !== fullReport?.previousValue) {
      if (!this.fullReport) {
        return;
      }

      this.chartOptions = this.buildChartOptions(this.fullReport);
    }
  }

  private buildChartOptions(report: IGroupsFullReport): any {
    const tasks = ['Task A', 'Task B', 'Task C'];  // Список задач
    const stages = [
      { name: 'Stage 1', avg: 75 },  // Этап 1
      { name: 'Stage 2', avg: 50 },  // Этап 2
      { name: 'Stage 3', avg: 90 },  // Этап 3
    ];  // Список этапов с произвольными средними значениями

    // Генерация случайных данных для графика
    const taskData = tasks.map((task, taskIndex) => {
      return stages.map((stage, stageIndex) => ({
        x: task,  // Название задачи
        y: stage.name,  // Название этапа
        value: +(Math.random() * stage.avg).toFixed(2),  // Случайный показатель на основе среднего значения этапа
        stageIndex: stageIndex,  // Индекс этапа для цвета
      }));
    }).flat();

    // Формирование серий для графика
    const series = stages.map((stage, stageIndex) => ({
      name: stage.name,
      type: 'scatter',
      data: taskData
        .filter(data => data.stageIndex === stageIndex)
        .map(data => ({
          name: `${data.x} - ${data.y}`,
          value: [data.x, data.value],
        })),
      symbolSize: (dataItem: any) => {
        // Генерация случайного размера пузырька
        const randomSize = Math.random() * 50 + 10; // Генерация случайного размера в пределах от 10 до 60
        return randomSize;  // Возвращаем случайный размер
      },
      itemStyle: {
        color: UiService.CHARTS_COLORS[stageIndex % UiService.CHARTS_COLORS.length],
      },
    }));

    return {
      textStyle: {
        color: '#B9B8CE',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => `${params.name}: ${params.value[1].toFixed(2)}`,
      },
      legend: {
        data: stages.map(stage => stage.name),
        textStyle: {
          color: '#B9B8CE',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 'normal',
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: tasks,
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: 'value',
        name: 'Performance',
        min: 0, // Устанавливаем минимальное значение для оси Y
        max: 100, // Устанавливаем максимальное значение для оси Y
      },
      series,
      color: UiService.CHARTS_COLORS,
    };
  }

}
