<div class="dashboard-page-root">
  <div class="row -filters-row">
    <group-info />

    <p-card>
      <dashboard-filters [form]="reportsFilterForm" [cities]="cities" [countries]="countries" [locations]="locations" />
    </p-card>
  </div>

  <div class="row -audits-row">
    <p-card>
      @if (fullReport) {
        <dashboard-audit-card auditType="Ongoing Audits" [audit]="fullReport.audits.onGoingAudit" />
      } @else {
        <div class="flex justify-center align-center" style="height: 106px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
    <p-card>
      @if (fullReport) {
        <dashboard-audit-card auditType="Total Audits" [audit]="fullReport.audits.totalAudit" />
      } @else {
        <div class="flex justify-center align-center" style="height: 106px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
    <p-card>
      @if (fullReport) {
        <dashboard-audit-card auditType="Upcoming Audits" [audit]="fullReport.audits.upCommigAudit" />
      } @else {
        <div class="flex justify-center align-center" style="height: 106px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Brand <ms-text weight="700">Indicators</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.indicators?.length) {
        <no-data-block style="height: 400px;" />
      } @else if (fullReport) {
        <dashboard-brand-indicators [indicators]="fullReport.indicators" />
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Pain <ms-text weight="700">Points</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.painPoints?.length) {
        <no-data-block style="height: 400px;" />
      } @else if (fullReport) {
        <dashboard-points-card [pointsData]="fullReport.painPoints" />
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>

    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Delight <ms-text weight="700">Points</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.delightPoints?.length) {
        <no-data-block style="height: 400px;" />
      } @else if (fullReport) {
        <dashboard-points-card [pointsData]="fullReport.delightPoints" />
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Performance of <ms-text weight="700">Tasks</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.surveyTasks?.length) {
        <no-data-block style="height: 400px;" />
      } @else if (fullReport) {
        <no-data-block style="height: 400px;" />
<!--        <dashboard-tasks-performance [tasks]="fullReport.surveyTasks" />-->
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Brand <ms-text weight="700">Journey</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.stages?.length) {
        <no-data-block style="height: 400px;" />
      } @else if (fullReport) {
        <dashboard-brand-journey [stages]="fullReport.stages" />
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Top <ms-text weight="700">Touchpoints</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.touchPoints?.length) {
        <no-data-block style="height: 400px;" />
      } @else if (fullReport) {
        <dashboard-top-touchpoints [touchPoints]="fullReport.touchPoints" />
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>

    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Top <ms-text weight="700">Countries</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.countries?.length) {
        <no-data-block style="height: 400px;" />
      } @else if (fullReport) {
        <dashboard-top-countries [countries]="fullReport.countries" />
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Top <ms-text weight="700">Cities</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.cities?.length) {
        <no-data-block style="height: 400px;" />
      } @else if (fullReport) {
        <dashboard-top-countries [countries]="fullReport.cities" />
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>

    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Top <ms-text weight="700">Locations</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.locations?.nodes) {
        <no-data-block style="height: 400px;" />
      } @else if (fullReport) {
        <dashboard-top-locations [locations]="fullReport.locations" />
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Brand <ms-text weight="700">Trends</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.brandTrends?.length) {
        <no-data-block style="height: 400px;"  />
      } @else if (fullReport) {
        <no-data-block style="height: 400px;"  />
<!--        <dashboard-brand-trends />-->
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Stage <ms-text weight="700">Trends</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.stageTrends?.length) {
        <no-data-block style="height: 400px;"  />
      } @else if (fullReport) {
        <no-data-block style="height: 400px;"  />
<!--        <dashboard-stage-trends />-->
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Touchpoint <ms-text weight="700">Trends</ms-text>
      </ms-text>
      @if (fullReport && !$any(fullReport)?.touch_pointsTrends?.length) {
        <no-data-block style="height: 400px;"  />
      } @else if (fullReport) {
        <no-data-block style="height: 400px;"  />
<!--        <dashboard-touchpoint-trends />-->
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>

  <div class="row">
    <p-card>
      <ms-text weight="300" color="#9a9a9a" fontSize="20px">
        Overall <ms-text weight="700">Recommendations</ms-text>
      </ms-text>
      @if (fullReport) {
        <dashboard-overall-recommendations />
      } @else {
        <div class="flex justify-center align-center" style="height: 400px;">
          <p-progressSpinner ariaLabel="loading" />
        </div>
      }
    </p-card>
  </div>
</div>
