<div class="comparison-filters-root flex flex-column" [formGroup]="form">
  <div class="filters-row flex justify-space-between">
    <p-multiSelect
        placeholder="Brands"
        formControlName="brands"
        [options]="brands"
        [showHeader]="false"
    />

    <p-multiSelect
        placeholder="Experiences"
        formControlName="channels"
        [options]="channels"
        [showHeader]="false"
    />

    <p-dropdown
        placeholder="Year"
        formControlName="year"
        [options]="years"
    />

    <p-multiSelect
        placeholder="Decade"
        formControlName="periods"
        [options]="periods"
        [showHeader]="false"
    />

    <p-multiSelect
        placeholder="Country"
        formControlName="countries"
        [options]="countries"
        [showHeader]="false"
    />
  </div>

  <div class="filters-row flex justify-space-between">
    <p-multiSelect
        placeholder="Locations"
        formControlName="outlets"
        [options]="outlets"
        [showHeader]="false"
    />

    <p-multiSelect
        placeholder="Touch Point"
        formControlName="touchPoints"
        [options]="[]"
        [showHeader]="false"
    />

    <p-multiSelect
        placeholder="Indicators"
        formControlName="indicators"
        [options]="[]"
        [showHeader]="false"
    />

    <p-multiSelect
        placeholder="Stage"
        formControlName="stages"
        [options]="[]"
        [showHeader]="false"
    />

    <p-dropdown
        placeholder="Compare By"
        formControlName="compare"
        [options]="compare"
    />

    <p-dropdown
        placeholder="Group By"
        formControlName="groupBy"
        [options]="groupedBy"
    />
  </div>
</div>
