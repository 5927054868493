import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { IGallery } from '../../../../../models/gallery/gallery.interface';
import { TextComponent } from '../../../../../ui/components/text/text.component';

@Component({
  selector: 'gallery-info',
  templateUrl: './gallery-info.component.html',
  styleUrl: './gallery-info.component.scss',
  standalone: true,
  imports: [
    TextComponent
  ]
})
export class GalleryInfoComponent {
  get questionText(): string {
    return this.config.data!.gallery.question.question?.split(':')?.[1];
  }

  constructor(
    public config: DynamicDialogConfig<{ gallery: IGallery; file: string }>,
  ) {}
}
