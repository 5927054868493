
export class RoutesHelper {
  static readonly EMPTY_PATH = '';
  static readonly ROOT_PATH = '/';
  static readonly AUTH_PATH = 'auth';
  static readonly LOGIN_PATH = 'login';
  static readonly FORGOT_PATH = 'forgot';
  static readonly DASHBOARD_PATH = 'dashboard';
  static readonly QUESTIONS_PATH = 'questions';
  static readonly GALLERY_PATH = 'gallery';
  static readonly COMPARISON_PATH = 'comparison';
  static readonly TRENDS_PATH = 'trends';
  static readonly EDIT_PROFILE_PATH = 'edit-profile';
  static readonly CHANGE_PASSWORD_PATH = 'change-password';
  static readonly NOT_FOUND_PATH = '404';
}
