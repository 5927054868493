import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { lesMiserablesMock } from '../../../../../mocks/les-miserables.mock';
import { UiService } from '../../../../../core/services/ui.service';
import {
  IGroupsFullReportLocations
} from '../../../../../models/groups-full-report/groups-full-report-locations.interface';

@Component({
  selector: 'dashboard-top-locations',
  templateUrl: './dashboard-top-locations.component.html',
  styleUrl: './dashboard-top-locations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxEchartsDirective],
  providers: [provideEcharts()],
})
export class DashboardTopLocationsComponent implements OnChanges, AfterViewInit {
  @Input() locations!: IGroupsFullReportLocations;
  @ViewChild(NgxEchartsDirective) ngxEcharts!: NgxEchartsDirective;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { locations } = changes;
    if (locations?.currentValue && locations?.previousValue !== locations?.currentValue) {
      if (!this.ngxEcharts) {
        return;
      }

      this.ngxEcharts.options = this.buildLocations(this.locations);
    }
  }

  buildLocations(locations: IGroupsFullReportLocations): any {
    return {
      textStyle: {
        color: '#B9B8CE',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
      tooltip: {},
      legend: [
        {
          data: locations.categories.map(function (a: any) {
            return a.name;
          }),
          textStyle: {
            color: '#B9B8CE',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 'normal',
          },
        }
      ],
      animationDurationUpdate: 1500,
      animationEasingUpdate: 'quinticInOut',
      series: [
        {
          type: 'graph',
          layout: 'circular',
          circular: {
            rotateLabel: true
          },
          data: locations.nodes,
          links: locations.links,
          categories: locations.categories,
          roam: true,
          label: {
            position: 'right',
            formatter: '{b}'
          },
          lineStyle: {
            color: 'source',
            curveness: 0.3
          },
        }
      ],
      color: UiService.CHARTS_COLORS,
    };
  }

  ngAfterViewInit(): void {
    this.ngxEcharts.options = this.buildLocations(this.locations);
    this.cdr.detectChanges();
  }
}
